import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";

import { FilterTools } from "tools/utils/filter.tool";
import { Types } from "tools/types/types";
import { Status } from "tools/types/status";

import { OrderProductType } from "tools/types/orderproducttype";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import RequestListDTO from "dto/app/requestlist.dto";
import { OrderProductFilterDto } from "./orderproductfilter.dto";

export class OrderProductDto implements Idto {
  id?: number | string;

  status?: number;
  paystatus?: number;

  idorder?: number | string;
  idproduct?: number | string;
  type?: number;

  description?: string;

  quantity?: number;

  vatcote?: number;

  priceunitarwov?: number;
  pricediscountper?: number;
  pricediscountunitarwov?: number;
  pricerealunitarwov?: number;
  vatunit?: number;
  pricerealunitar?: number;

  pricetotalwov?: number;
  pricediscounttotalwov?: number;
  pricerealtotalwov?: number;
  vattotal?: number;
  pricerealtotal?: number;

  orderProductFilters?: Array<OrderProductFilterDto>;

  constructor(
    id?: number | string,
    status?: number,
    paystatus?: number,
    idorder?: number | string,
    idproduct?: number | string,
    type?: number,
    description?: string,
    quantity?: number,
    vatcote?: number,
    priceunitarwov?: number,
    pricediscountper?: number,
    pricediscountunitarwov?: number,
    pricerealunitarwov?: number,
    vatunit?: number,
    pricerealunitar?: number,
    pricetotalwov?: number,
    pricediscounttotalwov?: number,
    pricerealtotalwov?: number,
    vattotal?: number,
    pricerealtotal?: number
  ) {
    this.id = id || 0;
    this.status = status || Status.ACTIVE;
    this.paystatus = paystatus || Status.ACTIVE;

    this.idorder = idorder || 0;
    this.idproduct = idproduct || 0;
    this.type = type || OrderProductType.BASE;

    this.description = description || "";

    this.quantity = quantity || 0;

    this.vatcote = vatcote || 0;

    this.priceunitarwov = priceunitarwov || 0;
    this.pricediscountper = pricediscountper || 0;
    this.pricediscountunitarwov = pricediscountunitarwov || 0;
    this.pricerealunitarwov = pricerealunitarwov || 0;
    this.vatunit = vatunit || 0;
    this.pricerealunitar = pricerealunitar || 0;

    this.pricetotalwov = pricetotalwov || 0;
    this.pricediscounttotalwov = pricediscounttotalwov || 0;
    this.pricerealtotalwov = pricerealtotalwov || 0;
    this.vattotal = vattotal || 0;
    this.pricerealtotal = pricerealtotal || 0;
  }
  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "paystatus",
        value: ["paystatus_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "type",
        value: ["type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "productObj",
        value: ["productObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "quantity",
        value: ["quantity"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "vatcote",
        value: ["vatcote"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "priceunitarwov",
        value: ["priceunitarwov"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricediscountper",
        value: ["pricediscountper"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricediscountunitarwov",
        value: ["pricediscountunitarwov"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricerealunitarwov",
        value: ["pricerealunitarwov"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "vatunit",
        value: ["vatunit"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricerealunitar",
        value: ["pricerealunitar"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricetotalwov",
        value: ["pricetotalwov"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricediscounttotalwov",
        value: ["pricediscounttotalwov"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricerealtotalwov",
        value: ["pricerealtotalwov"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "vattotal",
        value: ["vattotal"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pricerealtotal",
        value: ["pricerealtotal"],
      },
      {
        typeField: Types.FIELD_TYPE_ARRAY,
        label: "productFilters",
        value: ["orderProductFilters"],
        options: {
          fields: ["filterObj", "_name"],
          secondaryFields: ["filterDictionarObj", "_name"],
          displayArrayType: Types.FIELD_TYPE_MULTI_TEXT,
          stackOptions: {
            direction: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            spacing: 1,
            divider: null,
          },
        },
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "paystatus"),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "productObj",
        "_name"
      ),
      ComponentCommonTools.columns_StandardColumn(LL, "quantity"),
      ComponentCommonTools.columns_StandardColumn(LL, "vatcote"),
      ComponentCommonTools.columns_StandardColumn(LL, "priceunitarwov"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricediscountper"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricediscountunitarwov"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricerealunitarwov"),
      ComponentCommonTools.columns_StandardColumn(LL, "vatunit"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricerealunitar"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricetotalwov"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricediscounttotalwov"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricerealtotalwov"),
      ComponentCommonTools.columns_StandardColumn(LL, "vattotal"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricerealtotal"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getColumnsSpecial(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "paystatus"),
      ComponentCommonTools.columns_ConstantColumn(LL, "type"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "productObj",
        "_name"
      ),
      ComponentCommonTools.columns_StandardColumn(LL, "quantity"),
      ComponentCommonTools.columns_StandardColumn(LL, "pricerealtotal"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["delete", "detailspecial", "orderproductfilter"],
        ["detailspecial"],
        undefined,
        "orderproduct"
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(
    data: Array<OrderProductDto>
  ): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["identifier"])
        )
      );
    });
    return result;
  }

  static defaultGetRequestList = (
    req: RequestListDTO,
    parentId: string,
    parentType: string
  ): RequestListDTO => {
    const t = new RequestFilterDTO();
    t.field = "idorder";
    t.values = [parentId];

    req.filters = req.filters ?? [];

    let exist = false;
    for (const v of req.filters) {
      if (v.field !== "idorder") continue;

      exist = true;
      break;
    }
    if (!exist) req.filters.push(t);
    return req;
  };
}

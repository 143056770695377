import RequestSortCriteriaDTO from "dto/app/requestsortcriteria.dto";
import { PaypalTransactionRepository } from "repositories/sale/paypaltransaction.repository";
import { GeneralService } from "services/general.service";

export class PaypalTransactionService extends GeneralService<PaypalTransactionRepository> {
  constructor() {
    super(
      new PaypalTransactionRepository(),
      undefined,
      RequestSortCriteriaDTO.prepareSortCriteria("id", false)
    );
  }
}

import IGeneralRepository from "interfaces/irepository.interface";
import GeneralRepository from "../general.repository";

export interface IUserRepository extends IGeneralRepository {
  signup: (cb?: any, cbParams?: any, data?: any) => Promise<any>;
  logout: (cb?: any, cbParams?: any) => Promise<any>;
  login: (cb?: any, cbParams?: any, data?: any) => Promise<any>;
  getUserByToken: (token: string, cb?: any, cbParams?: any) => Promise<any>;
}
export class UserRepository
  extends GeneralRepository
  implements IUserRepository
{
  constructor() {
    super("user");
  }

  signup = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/signup`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  logout = async (cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/logout`;
    return await this.getObject(url, cb, cbParams);
  };

  login = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/signin`;
    return await this.postAxios(url, data, cb, cbParams);
  };

  getUserByToken = async (token: string, cb?: any, cbParams?: any) => {
    const url = `/${this.mainUrl}/signintoken/${token}`;
    return await this.getObject(url, cb, cbParams);
  };

  changeProfile = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/changeprofile`;
    return await this.putAxios(url, data, cb, cbParams);
  };

  changePassword = async (cb?: any, cbParams?: any, data?: any) => {
    const url = `/${this.mainUrl}/changepassword`;
    return await this.putAxios(url, data, cb, cbParams);
  };
}

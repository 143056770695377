import React, { useEffect, useState } from "react";
import { useResource } from "hooks/useResource";
import { RouteTools } from "tools/utils/route.tool";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { CommonTools } from "tools/utils/common.tool";
import { NavButton } from "components/elements/button/NavButton";
import { MenuDomDto } from "components/elements/menu/MenuDom";
import { Box } from "@mui/material";

type Props = {
  setOpen: (open: boolean) => void;
};
const LeftPartUserMenu: React.FC<Props> = ({ setOpen }) => {
  //   const { user } = useAuth();
  const { currentRoute } = useResource();
  const [adminMenu, setAdminMenu] = useState<Array<MenuDomDto> | null>(null);
  const [openedIdentifier, setOpenedIdentifier] = useState("");

  useEffect(() => {
    let t = RouteTools.getAdminLeftMenu();
    t = processAdminMenSelected(t);
    setAdminMenu(t);
  }, [currentRoute]);

  const processAdminMenSelected = (
    objects: Array<MenuDomDto> | undefined | boolean
  ) => {
    if (!objects) return false;
    if (!Array.isArray(objects)) return false;
    if (!objects.length) return false;

    let rez = [];

    for (let k in objects) {
      let obj = objects[k];
      let children = processAdminMenSelected(obj.children);

      let isChildSelected = false;

      if (children && children.length) {
        for (let i in children) {
          if (!children[i].isSelected) continue;
          isChildSelected = true;

          break;
        }
      }

      obj.children = children;

      obj.isSelected = false;

      if (isChildSelected) {
        obj.isSelected = true;
      } else {
        if (currentRoute) {
          let h = currentRoute.url.split("?")[0];

          if (
            obj.href == currentRoute.url ||
            obj.href == h ||
            (currentRoute &&
              currentRoute.historystate &&
              currentRoute.historystate._mainurl &&
              currentRoute.historystate._mainurl == obj.href)
          ) {
            obj.isSelected = true;
          }
        }
      }

      rez.push(obj);
    }

    // ---------

    return rez;
  };

  const openMenu = (item: any) => {
    if (item.identifier != openedIdentifier) {
      setOpenedIdentifier(item.identifier);
      setOpen(true);
    } else {
      setOpenedIdentifier("");
    }
  };

  const processAdminMenItemChildren = (
    item: MenuDomDto,
    level: number,
    i: number
  ) => {
    const nextLevel = level + 1;

    if (!item.isSelected && item.identifier != openedIdentifier) return <></>;

    return processAdminMenu(item.children, nextLevel, item, i);
  };

  const processAdminMenItemDetailBt = (item: any) => {
    if (!item.children) return <></>;

    if (!item.isSelected && item.identifier != openedIdentifier)
      return <ChevronRightRoundedIcon color="primary" sx={{ ml: "auto" }} />;

    return <ExpandMoreRoundedIcon color="primary" sx={{ ml: "auto" }} />;
  };

  const processAdminMenItemDetailIcon = (item: any) => {
    return item.getIcon();
  };

  const processAdminMenItem = (item: any, level: number, i: number) => {
    const key = CommonTools.generateRandomString(10);
    const keyUl = CommonTools.generateRandomString(10);
    return (
      <Box key={keyUl}>
        <li
          className={item.isSelected ? "active" : "incative"}
          key={key}
          onClick={() => {
            openMenu(item);
          }}
        >
          {processAdminMenItemDetailIcon(item)}

          {item.href ? (
            <NavButton href={item.href} sx={{ width: "100%" }}>
              {item.label}
            </NavButton>
          ) : (
            <span>{item.label}</span>
          )}
          {processAdminMenItemDetailBt(item)}
        </li>
        {processAdminMenItemChildren(item, level, i)}
      </Box>
    );
  };

  const processAdminMenu = (
    objects: Array<MenuDomDto> | undefined | boolean,
    level: number,
    parentObj?: any,
    i?: any
  ) => {
    if (!objects) return <></>;
    if (!Array.isArray(objects)) return <></>;
    if (!objects.length) return <></>;

    parentObj = parentObj ? parentObj : false;

    if (i === undefined) i = 1;

    const key = CommonTools.generateRandomString(10);

    return (
      <ul
        key={key}
        
      >
        {objects.map((item, i) => processAdminMenItem(item, level, i))}
      </ul>
    );
  };

  return adminMenu ? processAdminMenu(adminMenu, 1) : null;
};

export { LeftPartUserMenu };

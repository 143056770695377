import React, { createContext, useCallback, useEffect, useState } from "react";
import IProvider from "interfaces/provider.interface";
import { RouteTools } from "tools/utils/route.tool";
import { Loading } from "components/elements/loading/Loading";
import { Status } from "tools/types/status";
import { LocalStorageTools } from "api/localstorage.api";
import { DisplayLabelDto } from "dto/static/label.dto";
import { LabelService } from "services/static/label.service";
import ResultObjectDTO from "dto/app/resultobject.dto";
import { CommonTools } from "tools/utils/common.tool";
import RequestListDTO from "dto/app/requestlist.dto";
import ResultListDTO from "dto/app/resultlist.dto";
import RequestFilterDTO from "dto/app/requestfilter.dto";
import { LabelTypes } from "tools/types/labeltypes";
import { useLanguage } from "./LanguageProvider";
import { UserConfigType } from "tools/types/userconfigtype";
import { PageType } from "tools/types/pagetype";
import { NavigatorType } from "tools/types/navigatortype";
import { NavigatorSection } from "tools/types/navigatorsection";
import { Types } from "tools/types/types";
import { VideoType } from "tools/types/videotype";
import { FileType } from "tools/types/filetype";
import { FAQType } from "tools/types/faqtype";
import { FAQAnswerType } from "tools/types/faqanswertype";
import { FilterType } from "tools/types/filtertype";
import { AdvertisementType } from "tools/types/advertisementtype";
import { AdvertisementSection } from "tools/types/advertisementsection";
import { TargetType } from "tools/types/targettype";
import { GeneralService } from "services/general.service";
import { ConfigTypes } from "tools/types/configtypes";
import { OrderProductType } from "tools/types/orderproducttype";
import { JournalType } from "tools/types/jurnaltype";
import { ClientType } from "tools/types/clienttype";
import { PayMethodType } from "tools/types/paymethod";
import { InvoiceType } from "tools/types/invoicetype";

type Props = {
  LL: (str: string) => string;
};
export const LabelContext = createContext<Props>({
  LL: () => "",
});

const getLocalLabels = (): Array<DisplayLabelDto> => {
  const labels = LocalStorageTools.getObject("labels");
  if (!labels) return [];
  return labels;
};

var labelsLocalGlobal: any = getLocalLabels();
var labelsTmp: Array<DisplayLabelDto> = [];
var savedLabels: Array<string> = [];

const service = new LabelService();

export const LabelProvider: React.FC<IProvider> = ({ children }) => {
  // logger("LabelProvider",labelsLocalGlobal);
  const { selectedLanguageCode } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [hashLocal, setHashLocal] = useState<string>(getLocalHash());

  const [hash, setHash] = useState<string>("");

  const LL = (identifier: string): string => {
    if (labelsLocalGlobal === false) return identifier;
    const preparedIdentifier = CommonTools.prepareLabelIdentifier(identifier);

    let value = "";
    let founded = false;

    if (!selectedLanguageCode) return identifier;

    for (const item of labelsLocalGlobal) {
      // logger("LL", item, selectedLanguageCode);
      if (item.identifier === CommonTools.getPrefixLabel(preparedIdentifier)) {
        founded = true;
        // logger("LL", item, selectedLanguageCode);
        value = item[selectedLanguageCode.toLowerCase()];
        break;
      }
    }
    // logger("LL", labelsLocalGlobal,founded)
    if (founded) {
      value = value ?? identifier;
      return value;
    }

    saveLabel(preparedIdentifier);
    return identifier;
  };

  useEffect(() => {
    RouteTools.setLL(LL);
    Status.setLL(LL);
    UserConfigType.setLL(LL);
    PageType.setLL(LL);
    LabelTypes.setLL(LL);
    NavigatorType.setLL(LL);
    NavigatorSection.setLL(LL);
    Types.setLL(LL);
    VideoType.setLL(LL);
    FileType.setLL(LL);
    FAQType.setLL(LL);
    FAQAnswerType.setLL(LL);
    FilterType.setLL(LL);
    AdvertisementType.setLL(LL);
    AdvertisementSection.setLL(LL);
    TargetType.setLL(LL);
    GeneralService.setLL(LL);
    ConfigTypes.setLL(LL);
    OrderProductType.setLL(LL);
    JournalType.setLL(LL);
    ClientType.setLL(LL);
    PayMethodType.setLL(LL);
    InvoiceType.setLL(LL);
  }, []);

  const getHash = () => {
    service.getHash(handleGetHash, {});
  };

  const handleGetHash = (result: ResultObjectDTO) => {
    if (!result) return;
    if (result.error) return;
    if (!result.obj) return;
    const hash = CommonTools.processObjectField(result, ["obj", "hash"]);
    setHash(hash);
  };

  useEffect(() => {
    getHash();
  }, []);

  const checkLoading = useCallback(() => {
    let loading = false;
    if (!hash) loading = true;
    setLoading(loading);
  }, [hash]);

  useEffect(() => {
    checkLoading();
  }, [checkLoading]);

  const loadLabels = (pageNumber?: number) => {
    pageNumber = pageNumber ?? 1;
    const req = new RequestListDTO();
    req.page = pageNumber;
    req.onpage = 50;
    const filter = new RequestFilterDTO();
    filter.field = "type";
    filter.values = [LabelTypes.ADMIN.toString()];
    req.filters = [filter];

    // logger("loadLabels", pageNumber);
    service.getLabelListSite(handleGetList, {}, req);
  };

  const handleGetList = (result: ResultListDTO<DisplayLabelDto>) => {
    if (!result) return;
    if (result.error) return;
    if (!result.objects) return;
    if (!result.requestinfo) return;
    if (!result.requestinfo.page) return;
    if (!result.totalpages) return;
    labelsTmp = [...labelsTmp, ...result.objects];
    if (result.requestinfo.page < result.totalpages) {
      loadLabels(result.requestinfo.page + 1);
    } else {
      processLabels(labelsTmp);
    }
  };

  const processLabels = (labels: Array<DisplayLabelDto>) => {
    labelsLocalGlobal = labels;
    // logger("processLabels", labels);
    LocalStorageTools.saveObject("labels", labels);
    LocalStorageTools.saveValue("label_hash", hash);
    setHashLocal(hash);
  };

  const saveLabel = (identifier: string) => {
    if (savedLabels.includes(identifier)) return;
    savedLabels.push(identifier);
    // logger("saveLabel", identifier);

    if (!Array.isArray(labelsLocalGlobal)) {
      labelsLocalGlobal = [DisplayLabelDto.getLabelLocal(identifier)];
    } else {
      labelsLocalGlobal = [
        ...labelsLocalGlobal,
        DisplayLabelDto.getLabelLocal(identifier),
      ];
    }

    service.getLabel(identifier, false, {});
  };

  const checkLabelHash = useCallback(() => {
    if (!hash) return;
    if (hash === hashLocal) return;
    else loadLabels();
  }, [hash, hashLocal]);

  useEffect(() => {
    checkLabelHash();
  }, [checkLabelHash]);

  const value = {
    LL,
  };

  return loading ? (
    <Loading />
  ) : (
    <LabelContext.Provider value={value}>{children}</LabelContext.Provider>
  );
};

const getLocalHash = (): string => {
  const hash = LocalStorageTools.getValue("label_hash");
  if (!hash) return "";
  return hash;
};

import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { CurrencyDto } from "dto/static/currency.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";

export class PaypalWalletDto implements Idto {
  id?: number;
  status?: number;
  priority?: number;
  mode?: string;
  client_id?: string;
  client_secret?: string;
  app_id?: string;
  payment_action?: string;

  idcurrency?: number;
  currencyObj?: CurrencyDto;
  constructor(
    id?: number,
    status?: number,
    priority?: number,
    mode?: string,
    client_id?: string,
    client_secret?: string,
    app_id?: string,
    payment_action?: string,
    idcurrency?: number
  ) {
    this.id = id || 0;
    this.status = status || Status.ACTIVE;
    this.priority = priority || 0;
    this.mode = mode || "";
    this.client_id = client_id || "";
    this.client_secret = client_secret || "";
    this.app_id = app_id || "";
    this.payment_action = payment_action || "";
    this.idcurrency = idcurrency || 0;
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "mode",
        value: ["mode"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_id",
        value: ["client_id"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_secret",
        value: ["client_secret"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "app_id",
        value: ["app_id"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "payment_action",
        value: ["payment_action"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "currency",
        value: ["currencyObj", "_name"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_StandardColumn(LL, "mode"),
      ComponentCommonTools.columns_StandardColumn(LL, "client_id"),
      ComponentCommonTools.columns_StandardColumn(LL, "client_secret"),
      ComponentCommonTools.columns_StandardColumn(LL, "app_id"),
      ComponentCommonTools.columns_StandardColumn(LL, "payment_action"),
      ComponentCommonTools.columns_StandardObjectColumn(
        LL,
        "currencyObj",
        "_name"
      ),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail", "edit", "delete"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }
}

import { AclRolesDto } from "dto/user/aclroles.dto";
import Idto from "interfaces/idto.interface";

export class RouteDto implements Idto {
  routegroup: string;
  routename: string;
  routemethod: string;

  constructor(routegroup?: string, routename?: string, routemethod?: string) {
    this.routegroup = routegroup || "";
    this.routename = routename || "";
    this.routemethod = routemethod || "";
  }

  static getGroup(arr: RouteDto[]): GroupedAclDto[] {
    let groups: GroupedAclDto[] = [];
    arr.forEach((route: RouteDto) => {
      let group = groups.find(
        (g: GroupedAclDto) => g.routegroup === route.routegroup
      );
      if (!group) {
        group = new GroupedAclDto(route.routegroup);
        groups.push(group);
      }
      group.routes.push(
        new AclRolesDto(0, route.routename, route.routemethod, 0, 0)
      );
    });
    return groups.sort((a, b) => a.routegroup.localeCompare(b.routegroup));
  }

  static searchGroup(arr: GroupedAclDto[], group: string): GroupedAclDto[] {
    return arr.filter((g: GroupedAclDto) => g.routegroup.includes(group));
  }

  static getRouteMethodColor(method: string) {
    switch (method.toUpperCase()) {
      case "GET":
        return "success";
      case "POST":
        return "info";
      case "PUT":
        return "warning";
      case "DELETE":
        return "error";
      default:
        return "default";
    }
  }
}

export class GroupedAclDto {
  routegroup: string;
  routes: AclRolesDto[];

  constructor(routegroup?: string, routes?: AclRolesDto[]) {
    this.routegroup = routegroup || "";
    this.routes = routes || [];
  }
}

export class AlcGroup implements Idto {
  routegroup: string;
  idrole: number;
  value: number;

  constructor(routegroup?: string, idrole?: number, value?: number) {
    this.routegroup = routegroup || "";
    this.idrole = idrole || 0;
    this.value = value || 0;
  }
}

export class AclDto implements Idto {
  routename: string;
  routemethod: string;
  acceptedidsrole: number[];
  acceptedidsscope: number[];

  constructor(
    routename?: string,
    routemethod?: string,
    acceptedidsrole?: number[],
    acceptedidsscope?: number[]
  ) {
    this.routename = routename || "";
    this.routemethod = routemethod || "";
    this.acceptedidsrole = acceptedidsrole || [];
    this.acceptedidsscope = acceptedidsscope || [];
  }

  static getFullRouteName(object: AclDto): string {
    if (!object) return "";
    const routeMethod = object.routemethod ? object.routemethod : "";
    const routeName = object.routename ? object.routename : "";
    return `${routeMethod.toLowerCase()}-${routeName.toLowerCase()}`;
  }
}

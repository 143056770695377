import { useEffect, useMemo, useState } from "react";
import { useResource } from "./useResource";

type Props = (useDefaultApproach?: boolean) => {
  listUrlThird: string;
  setListUrlThird: (listUrlThird: string) => void;
};

const useGetThirdUrlsList: Props = (useDefaultApproach) => {
  if (!useDefaultApproach) useDefaultApproach = true;

  const [listUrlThird, setListUrlThird] = useState("");

  const { _setListThirdUrl } = useResource();

  useEffect(() => {
    if (useDefaultApproach) {
      _setListThirdUrl(listUrlThird);
    }
  }, [listUrlThird]);

  return useMemo(
    () => ({
      listUrlThird,
      setListUrlThird,
    }),
    [listUrlThird]
  );
};

export { useGetThirdUrlsList };

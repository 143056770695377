import { Box, Stack, IconButton, Typography } from "@mui/material";
import React from "react";
import { NavButton } from "../button/NavButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLabel } from "hooks/useLabel";
import { BreadcrumbDto } from "dto/app/breadcrumb.dto";
import { MyBreadcrumbs } from "../breadcrumb/MyBreadcrumbs";

type Props = {
  mainObject: string;
  mainUrl: string;
  listUrl: string;
};
const HeaderAdd: React.FC<Props> = ({ mainObject, mainUrl, listUrl }) => {
  const { LL } = useLabel();
  
  return (
    <React.Fragment>
      <MyBreadcrumbs
        objects={BreadcrumbDto.getBreadcrumbAdd(mainUrl, mainObject)}
        mainObject={mainObject}
      />

      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",

          width: "100%",
        }}
        maxWidth="md"
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"flex-start"}
          spacing={1}
        >
          <Box>
            <NavButton
              href={listUrl}
              sx={{ ml: "auto" }}
              _hstate={{}}
              _mainurl={mainUrl}
              component={IconButton}
            >
              <ArrowBackIcon />
            </NavButton>
          </Box>
          <Box>
            <Typography variant="h4" sx={{ my: 3, ml: 3 }}>
              {LL("AddBlock")}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export { HeaderAdd };

import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";

import { MyTextField } from "components/elements/form/MyTextField";
import { StaticSelect } from "components/elements/select/StaticSelect";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { SelectPage } from "components/static/page/SelectPage";
import { NavigatorDto } from "dto/static/navigator.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";

import { NavigatorSection } from "tools/types/navigatorsection";
import { NavigatorType } from "tools/types/navigatortype";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: NavigatorDto;
  onSubmit: (data: NavigatorDto) => void;
  loading: boolean;
  forAdd?: boolean;
};
const FormNavigator: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
  forAdd = false,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , setLanguage, , vResults] =
    useForm<NavigatorDto>(
      defaultObject,
      RequiredValidator.getValidators(["_name"])
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };

  const processFieldByType = () => {
    if (!obj) return null;
    if (!obj.navigatortype) return null;
    if (obj.navigatortype == NavigatorType.NO_URL) return null;
    if (obj.navigatortype == NavigatorType.URL_TOPAGE)
      return (
        <Box mt={3}>
          <SelectPage value={obj.idpage} setObjectField={setObjField} />
        </Box>
      );
    if (
      obj.navigatortype == NavigatorType.URL_EXTERN ||
      obj.navigatortype == NavigatorType.URL_LOCAL
    )
      return (
        <Box mt={3}>
          <MyTextField
            field="url"
            label={LL("field_url")}
            setObjectField={setObjField}
            value={obj.url}
          />
        </Box>
      );
    if (obj.navigatortype == NavigatorType.ANCHOR) {
      return (
        <Box>
          <Box mt={3}>
            <MyTextField
              field="url"
              label={LL("field_url")}
              setObjectField={setObjField}
              value={obj.url}
            />
          </Box>
          <Box mt={3}>
            <MyTextField
              field="anchor"
              label={LL("field_anchor")}
              setObjectField={setObjField}
              value={obj.anchor}
            />
          </Box>
        </Box>
      );
    }
    return null;
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="identifier"
            label={LL("field_identifier")}
            setObjectField={setObjField}
            value={obj.identifier}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="order"
            label={LL("field_order")}
            setObjectField={setObjField}
            value={obj.order}
            vResults={vResults}
          />
        </Box>
        {forAdd && (
          <Box mt={3}>
            <LanguageSelect value={obj._idlang ?? ""} setValue={setLanguage} />
          </Box>
        )}
        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>
        <Box mt={3}>
          <StaticSelect
            field="navigatortype"
            setObjField={setObjField}
            value={obj.navigatortype}
            service={NavigatorType}
          />
        </Box>
        <Box mt={3}>
          <StaticSelect
            field="navigatorsection"
            setObjField={setObjField}
            value={obj.navigatorsection}
            service={NavigatorSection}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_name"
            label={LL("field_name")}
            setObjectField={setObjField}
            value={obj._name}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="_title"
            label={LL("field_title")}
            setObjectField={setObjField}
            value={obj._title}
            vResults={vResults}
          />
        </Box>

        {processFieldByType()}

        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormNavigator };

import { PageService } from "services/static/page.service";
import { CommonTools } from "./common.tool";
import { SocialPageService } from "services/static/socialpage.service";
import { FAQService } from "services/static/faq.service";
import { CategoryProductService } from "services/static/categoryproduct.service";
import { ProductService } from "services/product/product.service";
import { OfferService } from "services/product/offer.service";
import { BlogService } from "services/static/blog.service";
import { FilterDictionarService } from "services/product/filterdictionar.service";
import { PartnerService } from "services/static/partner.service";
import { PayMethodService } from "services/static/paymethod.service";
import { TestimonialService } from "services/static/testimonial.service";
import { DeliveryMethodService } from "services/delivery/deliverymethod.service";
import { OrderService } from "services/sale/order.service";
import { GeneralService } from "services/general.service";
import { OrderProductService } from "services/sale/orderproduct.service";
import { UserService } from "services/user/user.service";
import { RoleService } from "services/user/role.service";


const pageService = new PageService();
const socialPageService = new SocialPageService();
const faqService = new FAQService();
const categoryProductService = new CategoryProductService();
const productService = new ProductService();
const offerService = new OfferService();
const blogService = new BlogService();
const filterDictionar = new FilterDictionarService();
const paymethodService = new PayMethodService();
const partnerService = new PartnerService();
const testimonialService = new TestimonialService();
const deliveryMethodService = new DeliveryMethodService();
const orderService = new OrderService();
const orderProductService = new OrderProductService();
const userService = new UserService();
const roleService = new RoleService();

export class ServiceTools {
  static _SaveCache: any = false;
  static _GetCache: any = false;

  static setSaveCache(_t: any): any {
    ServiceTools._SaveCache = _t;
  }

  static setGetCache(_t: any): any {
    ServiceTools._GetCache = _t;
  }

  public static getObjectName = async (
    id: string,
    type: string
  ): Promise<string> => {
    let _cache: any = false;
    if (ServiceTools._GetCache)
      _cache = ServiceTools._GetCache(id + "_" + type);
    if (_cache !== false) {
      return _cache;
    }

    let rez: string | boolean = false;

    if (
      rez === false &&
      (type === "page" ||
        type === "socialpage" ||
        type === "faq" ||
        type === "categoryproduct" ||
        type === "product" ||
        type === "offer" ||
        type === "blog" ||
        type === "filterdictionar" ||
        type === "paymethod" ||
        type === "partener" ||
        type === "testimonial" ||
        type === "deliverymethod" ||
        type === "order" ||
        type === "orderproduct" ||
        type === "user" ||
        type === "role" ||
        type === "paypaltransaction")
    )
      rez = await ServiceTools.getObjectName_Page(id, type);

    if (rez !== false) {
      const _rez: string = rez as string;
      if (ServiceTools._SaveCache)
        ServiceTools._SaveCache(_rez, id + "_" + type);
      return _rez;
    }

    return "";
  };

  public static getObjectName_Page = async (
    id: string,
    type: string
  ): Promise<string> => {
    let data;

    switch (type) {
      case "page": {
        data = await pageService.get(id);
        break;
      }
      case "socialpage": {
        data = await socialPageService.get(id);
        break;
      }
      case "faq": {
        data = await faqService.get(id);
        break;
      }
      case "categoryproduct": {
        data = await categoryProductService.get(id);
        break;
      }
      case "product": {
        data = await productService.get(id);
        break;
      }
      case "offer": {
        data = await offerService.get(id);

        break;
      }
      case "blog": {
        data = await blogService.get(id);
        break;
      }
      case "filterdictionar": {
        data = await filterDictionar.get(id);
        break;
      }
      case "paymethod": {
        data = await paymethodService.get(id);
        break;
      }
      case "partener": {
        data = await partnerService.get(id);
        break;
      }
      case "testimonial": {
        data = await testimonialService.get(id);
        break;
      }
      case "deliverymethod": {
        data = await deliveryMethodService.get(id);
        break;
      }
      case "order": {
        data = await orderService.get(id);
        break;
      }
      case "orderproduct": {
        data = await orderProductService.get(id);
        break;
      }
      case "user": {
        data = await userService.get(id);
        break;
      }
      case "role": {
        data = await roleService.get(id);
        break;
      }
      case "paypaltransaction": {
        data = { obj: { id } };
        break;
      }

      default: {
        return "";
      }
    }

    if (!data) return "";
    if (!data.obj) return "";

    if (type === "offer") {
      return CommonTools.processObjectField(data, ["obj", "real_price"]);
    }
    if (type === "order") {
      return `${GeneralService.LL("order")} ${CommonTools.processObjectField(
        data,
        ["obj", "id"]
      )}`;
    }
    if (type === "orderproduct") {
      return `${CommonTools.processObjectField(data, [
        "obj",
        "productObj",
        "_name",
      ])}`;
    }
    if (type === "user") {
      return CommonTools.processObjectField(data, ["obj", "screen_name"]);
    }
    if (type === "role") {
      return CommonTools.processObjectField(data, ["obj", "identifier"]);
    }
    if (type === "paypaltransaction") {
      return `${GeneralService.LL(
        "transaction"
      )} ${CommonTools.processObjectField(data, ["obj", "id"])}`;
    }
    return CommonTools.processObjectField(data, ["obj", "_name"]);
  };
}

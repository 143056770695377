import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class PaypalWalletRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("paypalwallet");
  }
}

import { GridRenderCellParams } from "@mui/x-data-grid";
import { ColumnSendEmail } from "components/notification/sendtest/ColumnFromEmail";
import { CommonTools } from "./common.tool";
import { Print } from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";

export class ListColumns {
  static columnSendEmail = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnSendEmail object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "send_email";
    obj["headerName"] = LL("headerName_send_email");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 30;

    return obj;
  };

  public static columns_Print = (
    LL: any,
    field?: string,
    width?: any,
    sortable?: boolean,
    hideable?: boolean,
    type?: string
  ) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        field = field ?? "";
        if (params.row !== undefined) {
          if (params.row !== undefined && params.row.hasOwnProperty(field)) {
            const aUrl = CommonTools.processObjectField(params, ["row", field]);

            if (aUrl) {
              return (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <IconButton
                    href={aUrl}
                    target="_blank"
                    rel="noreferrer"
                    component={Link}
                  >
                    <Print color="secondary" />
                  </IconButton>
                </div>
              );
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
      },
    };
    obj["field"] = field + "_name";
    obj["headerName"] = LL("print");
    obj["hideable"] = hideable != undefined ? hideable : true;
    obj["sortable"] = false;
    if (width !== undefined) obj["width"] = width;
    else obj["flex"] = 1;
    if (type != undefined) obj["type"] = type;

    return obj;
  };
}

import { Grid } from "@mui/material";

import { Loading } from "components/elements/loading/Loading";

import { DeliveryMethodOfferDto } from "dto/delivery/deliverymethodoffer.dto";
import { IPage } from "interfaces/page.interface";
import React from "react";
import { DeliveryMethodOfferService } from "services/delivery/deliverymethodoffer.service";
import { DetailGrid } from "components/elements/detail/DetailGrid";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

import { useDetailSpecial } from "hooks/useDetailSpecial";
import { HeaderDetailSpecial } from "components/elements/detail/HeaderDetailSpecial";

const service = new DeliveryMethodOfferService();
const SpecialDeliveryMethodOfferDetail: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    editUrl,
    parentType,
    parentId,
    object,
    loading,
    mainListUrl,
    listUrlSecond,
  } = useDetailSpecial<DeliveryMethodOfferDto>(
    "deliverymethodoffer",
    currentRoute,
    get
  );

  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderDetailSpecial
        object={object}
        mainObject={mainObject}
        mainUrl={mainUrl}
        parentId={parentId}
        parentType={parentType}
        editUrl={editUrl}
        specialType="deliverymethodoffer"
        mainListUrl={mainListUrl}
        urlListSecond={listUrlSecond}
      />
      <DataDisplayBox>
        <Grid>
          <DetailGrid
            object={object}
            fields={DeliveryMethodOfferDto.getDetailFields()}
          />
        </Grid>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialDeliveryMethodOfferDetail };

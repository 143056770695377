import SelectOptions from "dto/app/selectoptions.dto";
import TbFilterDTO from "dto/app/tbfilter.dto";
import TbFilterListDTO from "dto/app/tbfilterlist.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FilterTools } from "tools/utils/filter.tool";
import { NavigatorSection } from "tools/types/navigatorsection";
import { NavigatorType } from "tools/types/navigatortype";

import { Status } from "tools/types/status";
import { Types } from "tools/types/types";
import { PageDto } from "./page.dto";

export class NavigatorDto implements Idto {
  id?: number | string;
  identifier?: string;
  order?: number;
  fixed?: number;
  status?: number;

  hierarchicalcode?: string;
  level?: number;
  idparent?: string;

  navigatortype?: number;
  navigatorsection?: number;

  idpage?: number;
  url?: string;

  _idlang?: number | string;
  _name?: string;
  _title?: string;
  anchor?: string;

  pageObj?: PageDto;

  constructor(
    _idlang?: number | string,
    idparent?: string,
    id?: number | string,
    identifier?: string,
    order?: number,
    fixed?: number,
    status?: number,
    _name?: string,
    _title?: string,
    url?: string,
    idpage?: number,
    navigatortype?: number,
    navigatorsection?: number,
    anchor?: string
  ) {
    this.id = id || 0;
    this.identifier = identifier || "";
    this.order = order || 0;
    this.fixed = fixed || 0;
    this.status = status || Status.ACTIVE;
    this._idlang = _idlang || 0;
    this._name = _name || "";
    this._title = _title || "";
    this.url = url || "";
    this.idpage = idpage || 0;
    this.navigatortype = navigatortype || NavigatorType.NO_URL;
    this.navigatorsection = navigatorsection || NavigatorSection.MAIN;
    this.idparent = idparent || "";
    this.anchor = anchor || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "identifier",
        value: ["identifier"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "order",
        value: ["order"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "navigatorsection",
        value: ["navigatorsection_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "navigatortype",
        value: ["navigatortype_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "name",
        value: ["_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "title",
        value: ["_title"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "url",
        value: ["url"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "anchor",
        value: ["anchor"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "order"),
      ComponentCommonTools.columns_StandardColumn(LL, "identifier"),
      ComponentCommonTools.columns_ConstantColumn(LL),
      ComponentCommonTools.columns_ConstantColumn(LL, "navigatorsection"),
      ComponentCommonTools.columns_ConstantColumn(LL, "navigatortype"),
      ComponentCommonTools.columns_StandardColumn(LL, "_name"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["edit", "detail", "delete", "children"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }

  static getFilters(): TbFilterListDTO {
    const obj = new TbFilterListDTO();

    let t: TbFilterDTO;

    t = new TbFilterDTO("search", FilterTools.fiterTypeText);
    obj.addFilter(t);

    t = new TbFilterDTO("status", FilterTools.fiterTypeSelectMultiple);
    t.setValues(Status.GA("clasificator", true));
    obj.addFilter(t);
    
    t = new TbFilterDTO("navigatorsection", FilterTools.fiterTypeSelectMultiple);
    t.setValues(NavigatorSection.GA("clasificator", true));
    obj.addFilter(t);

    t = new TbFilterDTO("navigatortype", FilterTools.fiterTypeSelectMultiple);
    t.setValues(NavigatorType.GA("clasificator", true));
    obj.addFilter(t);

    return obj;
  }

  static parseToSelectOptions(data: Array<NavigatorDto>): Array<SelectOptions> {
    if (!data) return [];
    if (!Array.isArray(data)) return [];
    if (!data.length) return [];
    let result: Array<SelectOptions> = [];
    data.forEach((element) => {
      result.push(
        new SelectOptions(
          CommonTools.processObjectField(element, ["id"]),
          CommonTools.processObjectField(element, ["_name"])
        )
      );
    });
    return result;
  }
}

import React from "react";
import YouTube from "react-youtube";
import ReactPlayer from "react-player";
import { VideoType } from "tools/types/videotype";


type Props = {
  videoId?: string | null;
  type: number;
  width?: number | string;
  height?: number | string;
  controls?: boolean;
};

const VideoPlayer: React.FC<Props> = ({
  videoId,
  type,
  width,
  height,
  controls = true,
}) => {
  videoId = videoId ?? "";

  width = width ?? "640px";
  height = height ?? "360px";

  if (!type) return null;
  if (!videoId) return null;
  if (type === VideoType.YOUTUBE) {
    return <YouTube videoId={videoId} opts={{ width, height }} />;
  } else if (type === VideoType.VIMEO) {
    return (
      <ReactPlayer
        url={"https://vimeo.com/" + videoId}
        width={width}
        height={height}
        controls={controls}
      />
    );
  } else {
    return null;
  }
};

export { VideoPlayer };

import PersonIcon from "@mui/icons-material/Person";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import SettingsSuggestRoundedIcon from "@mui/icons-material/SettingsSuggestRounded";
import PaymentsIcon from "@mui/icons-material/Payments";
import NotificationsIcon from "@mui/icons-material/Notifications";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

export class MenuDomDto {
  constructor(
    public identifier?: string,
    public children?: MenuDomDto[] | boolean,
    public href?: string | boolean,
    public label?: string,
    public icon?: string,
    public permissionhash?: string,
    public isSelected?: boolean
  ) {}

  getIcon = () => {
    if (this.icon === "amg_user") return <PersonIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_static")
      return <DisplaySettingsIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_nomenclature")
      return <AppRegistrationRoundedIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_product") return <ShoppingBagIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_system")
      return <SettingsSuggestRoundedIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_finance") return <PaymentsIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_sale") return <PointOfSaleIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_delivery")
      return <LocalShippingIcon sx={{ mr: 3 }} />;
    if (this.icon === "amg_notification")
      return <NotificationsIcon sx={{ mr: 3 }} />;
    return <></>;
  };
}

import { useEffect, useMemo, useState } from "react";
import { useResource } from "./useResource";
import { CommonTools } from "tools/utils/common.tool";


type Props = (
  mainObject: string,
  currentRoute: any
) => {
  listUrlThird: string;
};

const useGetThirdUrls: Props = (mainObject, currentRoute) => {
  const [listUrlThird, setListUrlThird] = useState("");

  const { _getListThirdUrl } = useResource();

  useEffect(() => {
    if (!mainObject || !currentRoute) return;
    const mu = CommonTools.generateMainUrl(mainObject);

    const lu = _getListThirdUrl(mu);
    setListUrlThird(lu);
  }, [mainObject, currentRoute]);

  return useMemo(
    () => ({
      listUrlThird,
    }),
    [listUrlThird]
  );
};

export { useGetThirdUrls };

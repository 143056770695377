import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";

import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { PaypalWalletDto } from "./paypalwallet.dto";

export class PaypalTransactionDto implements Idto {
  id?: number;
  idpaypalwallet?: number;
  idorder?: number;

  pp_intent?: string;
  pp_status?: string;

  tr_amount?: number;
  tr_idcurrency?: number;

  site_amount?: number;

  iduser?: number;
  data?: number;

  pp_txn_id?: string;
  pp_create_time?: string;

  pp_payer_name?: string;
  pp_payer_email?: string;
  pp_payer_id?: string;

  pp_payment_source?: string;
  pp_purchase_units?: string;
  pp_payer?: string;
  pp_links?: string;

  link_self?: string;
  link_approve?: string;
  link_update?: string;

  link_capture?: string;

  pp_full?: string;

  paypalwalletObj?: PaypalWalletDto;

  constructor(
    id?: number,
    idpaypalwallet?: number,
    idorder?: number,
    pp_intent?: string,
    pp_status?: string,
    tr_amount?: number,
    tr_idcurrency?: number,
    site_amount?: number,
    iduser?: number,
    data?: number,
    pp_txn_id?: string,
    pp_create_time?: string,
    pp_payer_name?: string,
    pp_payer_email?: string,
    pp_payer_id?: string,
    pp_payment_source?: string,
    pp_purchase_units?: string,
    pp_payer?: string,
    pp_links?: string,
    link_self?: string,
    link_approve?: string,
    link_update?: string,
    link_capture?: string,
    pp_full?: string
  ) {
    this.id = id || 0;
    this.idpaypalwallet = idpaypalwallet || 0;
    this.idorder = idorder || 0;
    this.pp_intent = pp_intent || "";
    this.pp_status = pp_status || "";
    this.tr_amount = tr_amount || 0;
    this.tr_idcurrency = tr_idcurrency || 0;
    this.site_amount = site_amount || 0;
    this.iduser = iduser || 0;
    this.data = data || 0;
    this.pp_txn_id = pp_txn_id || "";
    this.pp_create_time = pp_create_time || "";
    this.pp_payer_name = pp_payer_name || "";
    this.pp_payer_email = pp_payer_email || "";
    this.pp_payer_id = pp_payer_id || "";
    this.pp_payment_source = pp_payment_source || "";
    this.pp_purchase_units = pp_purchase_units || "";
    this.pp_payer = pp_payer || "";
    this.pp_links = pp_links || "";
    this.link_self = link_self || "";
    this.link_approve = link_approve || "";
    this.link_update = link_update || "";
    this.link_capture = link_capture || "";
    this.pp_full = pp_full || "";
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "idorder",
        value: ["idorder"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pp_intent",
        value: ["pp_intent"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pp_status",
        value: ["pp_status"],
      },

      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "site_amount",
        value: ["site_amount"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pp_txn_id",
        value: ["pp_txn_id"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pp_create_time",
        value: ["pp_create_time"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pp_payer_name",
        value: ["pp_payer_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pp_payer_email",
        value: ["pp_payer_email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "pp_payer_id",
        value: ["pp_payer_id"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_StandardColumn(LL, "idorder"),
      ComponentCommonTools.columns_StandardColumn(LL, "pp_intent"),
      ComponentCommonTools.columns_StandardColumn(LL, "pp_status"),
      ComponentCommonTools.columns_StandardColumn(LL, "site_amount"),
      ComponentCommonTools.columns_StandardColumn(LL, "pp_txn_id"),
      ComponentCommonTools.columns_StandardColumn(LL, "pp_create_time"),
      ComponentCommonTools.columns_StandardColumn(LL, "pp_payer_name"),
      ComponentCommonTools.columns_StandardColumn(LL, "pp_payer_email"),
      ComponentCommonTools.columns_StandardColumn(LL, "pp_payer_id"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail", "paypaltransactionhistory"],
        ["detail"],
        cbParent,
        specialType
      ),
    ];
  }
}

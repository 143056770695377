import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useReactPath } from "hooks/useReactPath";

import { RouteTools } from "tools/utils/route.tool";

// import { Md5 } from "ts-md5";
import { NotFoundPage } from "pages/general/NotFoundPage";
import { LoginPage } from "pages/authentication/LoginPage";
import { ForbiddenPage } from "pages/general/ForbiddenPage";
import { HomePage } from "pages/general/HomePage";
import { useResource } from "hooks/useResource";
import { SignUpPage } from "pages/authentication/SignUpPage";
import { Language } from "pages/static/language/Language";
import { DetailLanguage } from "pages/static/language/DetailLanguage";
import { AddLanguage } from "pages/static/language/AddLanguage";
import { EditLanguage } from "pages/static/language/EditLanguage";
import { Label } from "pages/static/label/Label";
import { DetailLabel } from "pages/static/label/DetailLabel";
import { EditLabel } from "pages/static/label/EditLabel";
import { DetailConfig } from "pages/static/config/DetailConfig";
import { EditConfig } from "pages/static/config/EditConfig";
import { Config } from "pages/static/config/Config";
import { User } from "pages/user/user/User";
import { DetailUser } from "pages/user/user/DetailUser";
import { AddUser } from "pages/user/user/AddUser";
import { EditUser } from "pages/user/user/EditUser";
import { Role } from "pages/user/role/Role";
import { DetailRole } from "pages/user/role/DetailRole";
import { AddRole } from "pages/user/role/AddRole";
import { EditRole } from "pages/user/role/EditRole";

import { DetailPage } from "pages/static/page/DetailPage";
import { Page } from "pages/static/page/Page";
import { EditPage } from "pages/static/page/EditPage";
import { AddPage } from "pages/static/page/AddPage";
import { SpecialAttachment } from "components/special/attachment/SpecialAttachment";
import { SpecialAttachmentDetail } from "components/special/attachment/SpecialAttachmentDetail";
import { SpecialAttachmentAdd } from "components/special/attachment/SpecialAttachmentAdd";
import { SpecialAttachmentEdit } from "components/special/attachment/SpecialAttachmentEdit";

import { SpecialGallery } from "components/special/gallery/SpecialGallery";
import { SpecialGalleryAdd } from "components/special/gallery/SpecialGalleryAdd";
import { SpecialGalleryDetail } from "components/special/gallery/SpecialGalleryDetail";
import { SpecialGalleryEdit } from "components/special/gallery/SpecialGalleryEdit";
import { SpecialVideo } from "components/special/video/SpecialVideo";
import { SpecialVideoAdd } from "components/special/video/SpecialVideoAdd";
import { SpecialVideoDetail } from "components/special/video/SpecialVideoDetail";
import { SpecialVideoEdit } from "components/special/video/SpecialVideoEdit";

import { AddCountry } from "pages/static/country/AddCountry";
import { Country } from "pages/static/country/Country";
import { DetailCountry } from "pages/static/country/DetailCountry";
import { EditCountry } from "pages/static/country/EditCountry";

import { AddCurrency } from "pages/static/currency/AddCurrency";
import { Currency } from "pages/static/currency/Currency";
import { DetailCurrency } from "pages/static/currency/DetailCurrency";
import { EditCurrency } from "pages/static/currency/EditCurrency";

import { SocialPage } from "pages/static/socialpage/SocialPage";
import { DetailSocialPage } from "pages/static/socialpage/DetailSocialPage";
import { EditSocialPage } from "pages/static/socialpage/EditSocialPage";
import { AddSocialPage } from "pages/static/socialpage/AddSocialPage";

import { FAQ } from "pages/static/faq/FAQ";
import { DetailFAQ } from "pages/static/faq/DetailFAQ";
import { EditFAQ } from "pages/static/faq/EditFAQ";
import { AddFAQ } from "pages/static/faq/AddFAQ";

import { Navigator } from "pages/static/navigator/Navigator";
import { AddNavigator } from "pages/static/navigator/AddNavigator";
import { DetailNavigator } from "pages/static/navigator/DetailNavigator";
import { EditNavigator } from "pages/static/navigator/EditNavigator";
import { SpecialFAQAnswer } from "components/special/faqanswer/SpecialFAQAnswer";
import { SpecialFAQAnswerAdd } from "components/special/faqanswer/SpecialFAQAnswerAdd";
import { SpecialFAQAnswerDetail } from "components/special/faqanswer/SpecialFAQAnswerDetail";
import { SpecialFAQAnswerEdit } from "components/special/faqanswer/SpecialFAQAnswerEdit";
import {
  AddTypeLocation,
  DetailTypeLocation,
  EditTypeLocation,
  TypeLocation,
} from "pages/nomenclature/typelocation/TypeLocation";
import { SpecialFAQRelation } from "components/special/faqrelation/SpecialFAQRelation";
import { SpecialFAQRelationAdd } from "components/special/faqrelation/SpecialFAQRelationAdd";
import { SpecialFAQRelationDetail } from "components/special/faqrelation/SpecialFAQRelationDetail";
import { SpecialFAQRelationEdit } from "components/special/faqrelation/SpecialFAQRelationEdit";

import { Blog } from "pages/static/blog/Blog";
import { AddBlog } from "pages/static/blog/AddBlog";
import { DetailBlog } from "pages/static/blog/DetailBlog";
import { EditBlog } from "pages/static/blog/EditBlog";

import { CategoryBlog } from "pages/static/categoryblog/CategoryBlog";
import { AddCategoryBlog } from "pages/static/categoryblog/AddCategoryBlog";
import { DetailCategoryBlog } from "pages/static/categoryblog/DetailCategoryBlog";
import { EditCategoryBlog } from "pages/static/categoryblog/EditCategoryBlog";

import { CategoryProduct } from "pages/product/categoryproduct/CategoryProduct";
import { DetailCategoryProduct } from "pages/product/categoryproduct/DetailCategoryProduct";
import { EditCategoryProduct } from "pages/product/categoryproduct/EditCategoryProduct";
import { AddCategoryProduct } from "pages/product/categoryproduct/AddCategoryProduct";

import { Filter } from "pages/product/filter/Filter";
import { EditFilter } from "pages/product/filter/EditFilter";
import { AddFilter } from "pages/product/filter/AddFilter";
import { DetailFilter } from "pages/product/filter/DetailFilter";

import { FilterDictionar } from "pages/product/filterdictionar/FilterDictionar";
import { EditFilterDictionar } from "pages/product/filterdictionar/EditFilterDictionar";
import { AddFilterDictionar } from "pages/product/filterdictionar/AddFilterDictionar";
import { DetailFilterDictionar } from "pages/product/filterdictionar/DetailFilterDictionar";

import { AddAdvertisement } from "pages/static/advertisement/AddAdvertisement";
import { Advertisement } from "pages/static/advertisement/Advertisement";
import { DetailAdvertisement } from "pages/static/advertisement/DetailAdvertisement";
import { EditAdvertisement } from "pages/static/advertisement/EditAdvertisement";

import { Product } from "pages/product/product/Product";
import { AddProduct } from "pages/product/product/AddProduct";
import { DetailProduct } from "pages/product/product/DetailProduct";
import { EditProduct } from "pages/product/product/EditProduct";

import { SpecialOffer } from "components/special/offer/SpecialOffer";
import { SpecialOfferAdd } from "components/special/offer/SpecialOfferAdd";
import { SpecialOfferEdit } from "components/special/offer/SpecialOfferEdit";
import { SpecialOfferDetail } from "components/special/offer/SpecialOfferDetail";

import { SpecialProductFilter } from "components/special/productfilter/SpecialProductFilter";
import { SpecialProductFilterAdd } from "components/special/productfilter/SpecialProductFilterAdd";
import { SpecialProductFilterEdit } from "components/special/productfilter/SpecialProductFilterEdit";
import { SpecialProductFilterDetail } from "components/special/productfilter/SpecialProductFilterDetail";

import { SpecialOfferFilter } from "components/special/offerfilter/SpecialOfferFilter";
import { SpecialOfferFilterAdd } from "components/special/offerfilter/SpecialOfferFilterAdd";
import { SpecialOfferFilterDetail } from "components/special/offerfilter/SpecialOfferFilterDetail";
import { SpecialOfferFilterEdit } from "components/special/offerfilter/SpecialOfferFilterEdit";

import { AddLocation } from "pages/static/location/AddLocation";
import { EditLocation } from "pages/static/location/EditLocation";
import { DetailLocation } from "pages/static/location/DetailLocation";
import { Location } from "pages/static/location/Location";
import { ProfilePage } from "pages/user/profile/ProfilePage";

import { PayMethod } from "pages/static/paymethod/PayMethod";
import { AddPayMethod } from "pages/static/paymethod/AddPayMethod";
import { DetailPayMethod } from "pages/static/paymethod/DetailPayMethod";
import { EditPayMethod } from "pages/static/paymethod/EditPayMethod";

import { AddDeliveryMethod } from "pages/delivery/deliverymethod/AddDeliveryMethod";
import { DeliveryMethod } from "pages/delivery/deliverymethod/DeliveryMethod";
import { DetailDeliveryMethod } from "pages/delivery/deliverymethod/DetailDeliveryMethod";
import { EditDeliveryMethod } from "pages/delivery/deliverymethod/EditDeliveryMethod";

import { AddDeliveryMethodLocation } from "pages/delivery/deliverymethodlocation/AddDeliveryMethodLocation";
import { DeliveryMethodLocation } from "pages/delivery/deliverymethodlocation/DeliveryMethodLocation";
import { DetailDeliveryMethodLocation } from "pages/delivery/deliverymethodlocation/DetailDeliveryMethodLocation";
import { EditDeliveryMethodLocation } from "pages/delivery/deliverymethodlocation/EditDeliveryMethodLocation";

import { SpecialDeliveryMethodOffer } from "components/special/deliverymethodoffer/SpecialDeliveryMethodOffer";
import { SpecialDeliveryMethodOfferAdd } from "components/special/deliverymethodoffer/SpecialDeliveryMethodOfferAdd";
import { SpecialDeliveryMethodOfferDetail } from "components/special/deliverymethodoffer/SpecialDeliveryMethodOfferDetail";
import { SpecialDeliveryMethodOfferEdit } from "components/special/deliverymethodoffer/SpecialDeliveryMethodOfferEdit";

import { DetailOrder } from "pages/sale/order/DetailOrder";
import { Order } from "pages/sale/order/Order";
import { EditOrder } from "pages/sale/order/EditOrder";

import { ConfigFile } from "pages/static/configfile/ConfigFile";
import { DetailConfigFile } from "pages/static/configfile/DetailConfigFile";
import { EditConfigFile } from "pages/static/configfile/EditConfigFile";

import { SpecialOrderJournal } from "pages/sale/orderjournal/SpecialOrderJournal";
import { SpecialOrderJournalDetail } from "pages/sale/orderjournal/SpecialOrderJournalDetail";

import { SpecialOrderProduct } from "pages/sale/orderproduct/SpecialOrderProduct";
import { SpecialOrderProductDetail } from "pages/sale/orderproduct/SpecialOrderProductDetail";

import { SpecialOrderProductFilter } from "pages/sale/orderproductfilter/SpecialOrderProductFilter";
import { SpecialOrderProductFilterDetail } from "pages/sale/orderproductfilter/SpecialOrderProductFilterDetail";

import { SpecialProductWishlist } from "components/special/productwishlist/SpecialProductWishlist";
import { SpecialProductWishlistDetail } from "components/special/productwishlist/SpecialProductWishlistDetail";

import { FromEmail } from "pages/notification/fromemail/FromEmail";
import { AddFromEmail } from "pages/notification/fromemail/AddFromEmail";
import { DetailFromEmail } from "pages/notification/fromemail/DetailFromEmail";
import { EditFromEmail } from "pages/notification/fromemail/EditFromEmail";

import { AddEmailTemplate } from "pages/notification/emailtemplate/AddEmailTemplate";
import { DetailEmailTemplate } from "pages/notification/emailtemplate/DetailEmailTemplate";
import { EditEmailTemplate } from "pages/notification/emailtemplate/EditEmailTemplate";
import { EmailTemplate } from "pages/notification/emailtemplate/EmailTemplate";
import { SMSTemplate } from "pages/notification/smstemplate/SMSTemplate";
import { AddSMSTemplate } from "pages/notification/smstemplate/AddSMSTemplate";
import { EditSMSTemplate } from "pages/notification/smstemplate/EditSMSTemplate";
import { DetailSMSTemplate } from "pages/notification/smstemplate/DetailSMSTemplate";
import { Notification } from "pages/notification/notification/Notification";
import { DetailNotification } from "pages/notification/notification/DetailNotification";
import { SpecialAclRoles } from "components/special/aclroles/SpecialAclRoles";
import { DetailInvoice } from "pages/sale/invoice/DetailInvoice";
import { Invoice } from "pages/sale/invoice/Invoice";
import { AddPaypalWallet } from "pages/sale/paypalwallet/AddPaypalWallet";
import { PaypalWallet } from "pages/sale/paypalwallet/PaypalWallet";
import { EditPaypalWallet } from "pages/sale/paypalwallet/EditPaypalWallet";
import { DetailPaypalWallet } from "pages/sale/paypalwallet/DetailPaypalWallet";
import { PaypalTransaction } from "pages/sale/paypaltransaction/PaypalTransaction";
import { DetailPaypalTransaction } from "pages/sale/paypaltransaction/DetailPaypalTransaction";
import { SpecialPaypalTransactionHistoryDetail } from "pages/sale/paypaltransactionhistory/SpecialPaypalTransactionHistoryDetail";
import { SpecialPaypalTransactionHistory } from "pages/sale/paypaltransactionhistory/SpecialPaypalTransactionHistory";

const specialModules = [
  "gallery",
  "attachment",
  "video",
  "faqanswer",
  "faqrelation",
  "offer",
  "productfilter",
  "deliverymethodoffer",
  "orderjournal",
  "orderproduct",
  "productwishlist",
  "aclroles",
  "paypaltransactionhistory",
];

const thirdLevelSpecialModules = ["offerfilter", "orderproductfilter"];

const MainLayout: React.FC = () => {
  const components: any = {
    SpecialPaypalTransactionHistory,
    SpecialPaypalTransactionHistoryDetail,

    SpecialDeliveryMethodOffer,
    SpecialDeliveryMethodOfferAdd,
    SpecialDeliveryMethodOfferEdit,
    SpecialDeliveryMethodOfferDetail,

    SpecialProductFilter,
    SpecialProductFilterAdd,
    SpecialProductFilterEdit,
    SpecialProductFilterDetail,

    SpecialOfferFilter,
    SpecialOfferFilterAdd,
    SpecialOfferFilterEdit,
    SpecialOfferFilterDetail,

    SpecialOffer,
    SpecialOfferAdd,
    SpecialOfferEdit,
    SpecialOfferDetail,

    SpecialFAQRelation,
    SpecialFAQRelationDetail,
    SpecialFAQRelationAdd,
    SpecialFAQRelationEdit,

    SpecialFAQAnswer,
    SpecialFAQAnswerDetail,
    SpecialFAQAnswerAdd,
    SpecialFAQAnswerEdit,

    SpecialAttachment,
    SpecialAttachmentDetail,
    SpecialAttachmentAdd,
    SpecialAttachmentEdit,

    SpecialGallery,
    SpecialGalleryDetail,
    SpecialGalleryAdd,
    SpecialGalleryEdit,

    SpecialVideo,
    SpecialVideoDetail,
    SpecialVideoAdd,
    SpecialVideoEdit,

    Language,
    DetailLanguage,
    AddLanguage,
    EditLanguage,

    Label,
    DetailLabel,
    EditLabel,

    Config,
    DetailConfig,
    EditConfig,
    User,
    DetailUser,
    AddUser,
    EditUser,

    Role,
    DetailRole,
    AddRole,
    EditRole,

    Page,
    DetailPage,
    EditPage,
    AddPage,

    Country,
    DetailCountry,
    AddCountry,
    EditCountry,

    Currency,
    DetailCurrency,
    AddCurrency,
    EditCurrency,

    SocialPage,
    DetailSocialPage,
    EditSocialPage,
    AddSocialPage,

    Navigator,
    AddNavigator,
    DetailNavigator,
    EditNavigator,

    FAQ,
    DetailFAQ,
    EditFAQ,
    AddFAQ,

    TypeLocation,
    AddTypeLocation,
    EditTypeLocation,
    DetailTypeLocation,

    Blog,
    AddBlog,
    DetailBlog,
    EditBlog,

    CategoryBlog,
    AddCategoryBlog,
    DetailCategoryBlog,
    EditCategoryBlog,

    CategoryProduct,
    AddCategoryProduct,
    DetailCategoryProduct,
    EditCategoryProduct,

    Filter,
    EditFilter,
    AddFilter,
    DetailFilter,

    FilterDictionar,
    EditFilterDictionar,
    AddFilterDictionar,
    DetailFilterDictionar,

    Advertisement,
    AddAdvertisement,
    DetailAdvertisement,
    EditAdvertisement,

    Product,
    AddProduct,
    DetailProduct,
    EditProduct,

    Location,
    AddLocation,
    EditLocation,
    DetailLocation,

    PayMethod,
    AddPayMethod,
    DetailPayMethod,
    EditPayMethod,

    DeliveryMethod,
    AddDeliveryMethod,
    DetailDeliveryMethod,
    EditDeliveryMethod,

    DeliveryMethodLocation,
    AddDeliveryMethodLocation,
    DetailDeliveryMethodLocation,
    EditDeliveryMethodLocation,

    Order,
    DetailOrder,
    EditOrder,

    ConfigFile,
    DetailConfigFile,
    EditConfigFile,

    SpecialOrderJournal,
    SpecialOrderJournalDetail,

    SpecialOrderProduct,
    SpecialOrderProductDetail,

    SpecialOrderProductFilter,
    SpecialOrderProductFilterDetail,

    SpecialProductWishlist,
    SpecialProductWishlistDetail,

    FromEmail,
    AddFromEmail,
    DetailFromEmail,
    EditFromEmail,

    EmailTemplate,
    AddEmailTemplate,
    DetailEmailTemplate,
    EditEmailTemplate,

    SMSTemplate,
    AddSMSTemplate,
    EditSMSTemplate,
    DetailSMSTemplate,

    Notification,
    DetailNotification,

    SpecialAclRoles,

    Invoice,
    DetailInvoice,

    AddPaypalWallet,
    PaypalWallet,
    EditPaypalWallet,
    DetailPaypalWallet,

    PaypalTransaction,
    DetailPaypalTransaction,
  };

  const { currentRoute, setCurrentRoute } = useResource();
  const location = useLocation();
  const pathObject = useReactPath();

  const [_currentpath, setCurrentPath] = useState("");
  const [_mainobject, setMainObject] = useState<any>(false);

  useEffect(() => {
    // var h = Md5.hashStr(pathObject.path).toString();
    // var hr = Md5.hashStr(pathObject.path).toString();

    var routeobj = RouteTools.parseLocation(location);
    routeobj.historystate = pathObject.historystate;

    if (routeobj.url === _currentpath) return;
    setCurrentPath(routeobj.url);

    setCurrentRoute(routeobj);
  }, [pathObject]);

  useEffect(() => {
    if (currentRoute && currentRoute._mainobject) {
      setMainObject(currentRoute._mainobject);
    }
  }, [currentRoute]);

  // ----------------------------------
  const processStandardModule_GetSpecialThirdLevel = () => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;

    let specprefix = "";

    if (path.length === 5) {
      //
    } else if (path[5] === "add") {
      specprefix = "Add";
    } else if (path[5] === "edit") {
      specprefix = "Edit";
    } else {
      specprefix = "Detail";
    }

    if (path[4] === "offerfilter") {
      return "SpecialOfferFilter" + specprefix;
    }

    if (path[4] === "orderproductfilter") {
      return "SpecialOrderProductFilter" + specprefix;
    }
  };

  const processStandardModule_GetSpecial = () => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;

    let specprefix = "";

    if (path.length === 3) {
      //
    } else if (path[3] === "add") {
      specprefix = "Add";
    } else if (path[3] === "edit") {
      specprefix = "Edit";
    } else {
      specprefix = "Detail";
    }

    if (path[2] === "gallery") {
      return "SpecialGallery" + specprefix;
    }

    if (path[2] === "attachment") {
      return "SpecialAttachment" + specprefix;
    }

    if (path[2] === "video") {
      return "SpecialVideo" + specprefix;
    }

    if (path[2] === "faqanswer") {
      return "SpecialFAQAnswer" + specprefix;
    }

    if (path[2] === "faqrelation") {
      return "SpecialFAQRelation" + specprefix;
    }

    if (path[2] === "offer") {
      return "SpecialOffer" + specprefix;
    }

    if (path[2] === "orderjournal") {
      return "SpecialOrderJournal" + specprefix;
    }
    if (path[2] === "orderproduct") {
      return "SpecialOrderProduct" + specprefix;
    }
    if (path[2] === "paypaltransactionhistory") {
      return "SpecialPaypalTransactionHistory" + specprefix;
    }

    if (path[2] === "deliverymethodoffer") {
      return "SpecialDeliveryMethodOffer" + specprefix;
    }

    if (path[2] === "productfilter") {
      return "SpecialProductFilter" + specprefix;
    }

    if (path[2] === "productwishlist") {
      return "SpecialProductWishlist" + specprefix;
    }

    if (path[2] === "aclroles") {
      return "SpecialAclRoles" + specprefix;
    }
  };

  const processStandardModule_GetPrefix = () => {
    if (!currentRoute) return "";
    const path = currentRoute._paths;
    if (path.length === 1) return "";
    if (path[1] === "edit") return "Edit";
    if (path[1] === "add") return "Add";

    if (
      path[2] !== undefined &&
      specialModules.includes(path[2]) &&
      !thirdLevelSpecialModules.includes(path[4] ?? "")
    )
      return "Special";

    if (path[4] !== undefined && thirdLevelSpecialModules.includes(path[4])) {
      return "SpecialThird";
    }

    return "Detail";
  };

  const processStandardModule_GetComponentName = () => {
    if (_mainobject === "language") return "Language";
    if (_mainobject === "label") return "Label";
    if (_mainobject === "config") return "Config";
    if (_mainobject === "user") return "User";
    if (_mainobject === "role") return "Role";
    if (_mainobject === "page") return "Page";
    if (_mainobject === "country") return "Country";
    if (_mainobject === "currency") return "Currency";
    if (_mainobject === "socialpage") return "SocialPage";
    if (_mainobject === "navigator") return "Navigator";
    if (_mainobject === "faq") return "FAQ";
    if (_mainobject === "invoice") return "Invoice";
    if (_mainobject === "paypalwallet") return "PaypalWallet";
    if (_mainobject === "paypaltransaction") return "PaypalTransaction";
    if (_mainobject === "typelocation") return "TypeLocation";
    if (_mainobject === "blog") return "Blog";
    if (_mainobject === "categoryblog") return "CategoryBlog";
    if (_mainobject === "categoryproduct") return "CategoryProduct";
    if (_mainobject === "filter") return "Filter";
    if (_mainobject === "filterdictionar") return "FilterDictionar";
    if (_mainobject === "advertisement") return "Advertisement";
    if (_mainobject === "product") return "Product";
    if (_mainobject === "location") return "Location";
    if (_mainobject === "paymethod") return "PayMethod";
    if (_mainobject === "deliverymethod") return "DeliveryMethod";
    if (_mainobject === "deliverymethodlocation")
      return "DeliveryMethodLocation";
    if (_mainobject === "order") return "Order";
    if (_mainobject === "configfile") return "ConfigFile";
    if (_mainobject === "fromemail") return "FromEmail";
    if (_mainobject === "emailtemplate") return "EmailTemplate";
    if (_mainobject === "smstemplate") return "SMSTemplate";
    if (_mainobject === "notification") return "Notification";
    return "";
  };

  const processStandardModule = () => {
    if (!_mainobject) return "";

    if (RouteTools.modules.indexOf(_mainobject) === -1) return "";

    const prefix = processStandardModule_GetPrefix();
    const cname = processStandardModule_GetComponentName();

    if (prefix === "Special") {
      return processStandardModule_GetSpecial();
    }

    if (prefix === "SpecialThird") {
      return processStandardModule_GetSpecialThirdLevel();
    }

    return prefix + cname;
  };

  const processMainObject = () => {
    if (!_mainobject) return <NotFoundPage currentRoute={currentRoute} />;

    if (_mainobject === "homepage")
      return <HomePage currentRoute={currentRoute} />;
    if (_mainobject === "login")
      return <LoginPage currentRoute={currentRoute} />;
    if (_mainobject === "signup")
      return <SignUpPage currentRoute={currentRoute} />;
    if (_mainobject === "forbidden")
      return <ForbiddenPage currentRoute={currentRoute} />;
    if (_mainobject === "notfound")
      return <NotFoundPage currentRoute={currentRoute} />;
    if (_mainobject === "profile")
      return (
        <ProfilePage currentRoute={currentRoute} mainObject={_mainobject} />
      );

    const tag = processStandardModule() ?? "";
    const DynamicComponent = components[tag] || null;
    if (DynamicComponent)
      return (
        <DynamicComponent
          currentRoute={currentRoute}
          mainObject={_mainobject}
        />
      );

    return <NotFoundPage currentRoute={currentRoute} />;
  };

  return processMainObject();
};

export { MainLayout };

import { getInstance } from "api/server.api";
import MessageDto from "../dto/app/message.dto";
import RequestListDTO from "../dto/app/requestlist.dto";
import { MessageTypes } from "tools/types/messagetypes";
import { logger } from "tools/utils/logger";
import { AxiosInstance } from "axios";
import { isTokenExpired } from "tools/utils/common.tool";
import { Config } from "tools/utils/config";

type Instance = {
  instance?: AxiosInstance;
  canExecute: boolean;
};

const processAxiosResult = (data: any) => {
  if (
    data &&
    data != undefined &&
    data != null &&
    data.messages &&
    data.messages != undefined &&
    data.messages != null
  ) {
    if (GeneralAxiosRepository.messageFunction) {
      for (let i in data.messages) {
        GeneralAxiosRepository.messageFunction(data.messages[i]);
      }
    }
  }
};

const processAxiosSuccess = (data: any, cb?: any, cbParams?: any) => {
  const result = data.data ? data.data : null;
  processAxiosResult(result);
  if (!cb) return result;

  cb(result, cbParams, data);

  return result;
};

const processError = (cb?: any, cbParams?: any) => {
  const obj = new MessageDto();
  obj.code = "403";
  obj.message = "FORBIDDEN";
  obj.type = MessageTypes.MESSAGE_ERROR;

  if (GeneralAxiosRepository.messageFunction) {
    GeneralAxiosRepository.messageFunction(obj);
  }

  if (GeneralAxiosRepository.reloadFunction)
    GeneralAxiosRepository.reloadFunction();
  if (!cb) return;
  cb(cbParams);
};

const processUnauthorized = (cb?: any, cbParams?: any) => {
  if (GeneralAxiosRepository.handleUnauthorized)
    GeneralAxiosRepository.handleUnauthorized();
  if (cb) cb(cbParams);
  if (GeneralAxiosRepository.reloadFunction)
    GeneralAxiosRepository.reloadFunction();
};

const processAxiosError = (error: any, cb?: any, cbParams?: any) => {
  if (
    error &&
    error != undefined &&
    error != null &&
    error.response &&
    error.response != undefined &&
    error.response != null &&
    error.response.data &&
    error.response.data != undefined &&
    error.response.data != null
  ) {
    if (error.response) {
      processAxiosSuccess(error.response, cb, cbParams);
    }
    if (error.response.status === 500) {
      const obj = new MessageDto();
      obj.code = error.response.status;
      obj.message = error.response.statusText;
      obj.type = MessageTypes.MESSAGE_ERROR;

      if (GeneralAxiosRepository.messageFunction) {
        GeneralAxiosRepository.messageFunction(obj);
      }
      if (GeneralAxiosRepository.serverError)
        GeneralAxiosRepository.serverError();
    } else {
      if (error.response.data.messages) {
        if (GeneralAxiosRepository.messageFunction) {
          for (let i in error.response.data.messages) {
            GeneralAxiosRepository.messageFunction(
              error.response.data.messages[i]
            );
          }
        }
      }
      if (error.response.status === 401) {
        processUnauthorized(cb, cbParams);
      }
    }
  } else if (error) {
    // logger("NetworkError", error);
    if (GeneralAxiosRepository.serverError)
      GeneralAxiosRepository.serverError();
  }

  return "";
};

export default class GeneralAxiosRepository {
  private static token: any = false;
  public static reloadFunction: any = false;
  public static messageFunction: any = false;

  public static serverError: any = false;

  public static handleUnauthorized: any = false;

  public static setHandleUnauthorized(_hu: any) {
    this.handleUnauthorized = _hu;
  }

  public static setReloadFunction(_rf: any) {
    this.reloadFunction = _rf;
  }
  public static setServerError(_ne: any) {
    this.serverError = _ne;
  }

  public static setMessageFunction(_mf: any) {
    this.messageFunction = _mf;
  }

  static setToken(token: any) {
    GeneralAxiosRepository.token = token;
  }

  static getToken() {
    // logger("GeneralAxiosRepository.getToken", GeneralAxiosRepository.token);
    if (GeneralAxiosRepository.token) return GeneralAxiosRepository.token();
    else return "";
  }

  async getAxiosInstance(options?: any): Promise<Instance> {
    const instance: Instance = {
      canExecute: true,
    };
    options = options ? options : {};

    const headers: any = {};
    headers["Content-Type"] = "application/json";

    const token = GeneralAxiosRepository.getToken();
    if (isTokenExpired(token)) instance.canExecute = false;
    // logger("getAxiosInstanceToken", token, instance);
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    options.headers = headers;
    instance.instance = getInstance(options);
    return instance;
  }

  async getAxios(url: string, options?: object, cb?: any, cbParams?: any) {
    const instance = await this.getAxiosInstance(options);
    // logger("getAxios", url, instance);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute && url !== Config.URL_GENERATE_TOKEN) {
      processError(cb, cbParams);
      return;
    }
    try {
      const data = await axiosInstance.get(url, options);
      return processAxiosSuccess(data, cb, cbParams);
    } catch (error) {
      return processAxiosError(error, cb, cbParams);
    }
  }

  async postAxios(url: string, data?: any, cb?: any, cbParams?: any) {
    const instance = await this.getAxiosInstance();
    // logger("getAxios", url);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbParams);
      return;
    }

    try {
      const result = await axiosInstance.post(url, JSON.stringify(data));
      return processAxiosSuccess(result, cb, cbParams);
    } catch (error) {
      return processAxiosError(error, cb, cbParams);
    }
  }
  async postAxiosMultipart(url: string, data?: any, cb?: any, cbParams?: any) {
    const instance = await this.getAxiosInstance();
    // logger("getAxios", url);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbParams);
      return;
    }
    try {
      const result = await axiosInstance.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return processAxiosSuccess(result, cb, cbParams);
    } catch (error) {
      return processAxiosError(error, cb, cbParams);
    }
  }

  putAxiosMultipart = async (
    url: string,
    data?: any,
    cb?: any,
    cbParams?: any
  ) => {
    const instance = await this.getAxiosInstance();

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbParams);
      return;
    }
    try {
      const result = await axiosInstance.put(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return processAxiosSuccess(result, cb, cbParams);
    } catch (error) {
      return processAxiosError(error, cb, cbParams);
    }
  };
  async putAxios(url: string, data?: any, cb?: any, cbParams?: any) {
    const instance = await this.getAxiosInstance();
    // logger("getAxios", url);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbParams);
      return;
    }
    try {
      const result = await axiosInstance.put(url, JSON.stringify(data));
      return processAxiosSuccess(result, cb, cbParams);
    } catch (error) {
      return processAxiosError(error, cb, cbParams);
    }
  }
  async deleteAxios(url: string, cb?: any, cbParams?: any) {
    const instance = await this.getAxiosInstance();
    // logger("getAxios", url);

    const axiosInstance = instance.instance;
    if (!axiosInstance) return;
    if (!instance.canExecute) {
      processError(cb, cbParams);
      return;
    }
    try {
      const result = await axiosInstance.delete(url);
      return processAxiosSuccess(result, cb, cbParams);
    } catch (error) {
      return processAxiosError(error, cb, cbParams);
    }
  }

  async getObject(url: string, cb?: any, cbParams?: any) {
    return await this.getAxios(url, {}, cb, cbParams);
  }

  static parseListData(data?: RequestListDTO) {
    data = data ? data : new RequestListDTO();
    const result: any = {};
    result.params = {};

    if (data.page != null && data.page !== undefined) {
      result.params.page = data.page;
    }

    if (data.onpage != null && data.onpage !== undefined) {
      result.params.onpage = data.onpage;
    }

    if (
      data.sortcriteria != null &&
      data.sortcriteria !== undefined &&
      data.sortcriteria.length
    ) {
      let str = "";
      for (let i in data.sortcriteria) {
        str += str ? "|" : "";
        str += data.sortcriteria[i].field;
        str += ",";
        str += data.sortcriteria[i].asc ? "asc" : "";
      }
      result.params.order = str;
    }

    if (
      data.filters != null &&
      data.filters !== undefined &&
      data.filters.length
    ) {
      let str = "";
      for (let i in data.filters) {
        str += str ? "|" : "";
        str += data.filters[i].field;
        str += ",";
        str += data.filters[i].values ? data.filters[i].values?.join(",") : "";
      }
      result.params.filters = str;
    }

    return result;
  }

  async getListObject(
    url: string,
    cb?: any,
    cbParams?: any,
    data?: RequestListDTO
  ) {
    const options = GeneralAxiosRepository.parseListData(data);
    return await this.getAxios(url, options, cb, cbParams);
  }
}

import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class PaypalTransactionHistoryRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("paypaltransactionhistory");
  }
}

import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { MyTextField } from "components/elements/form/MyTextField";
import { StatusSelect } from "components/elements/select/StatusSelect";
import { SelectCurrency } from "components/static/currency/SelectCurrency";
import { PaypalWalletDto } from "dto/sale/paypalwallet.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import React from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  defaultObject: PaypalWalletDto;
  onSubmit: (data: PaypalWalletDto) => void;
  loading: boolean;
};
const FormPaypalWallet: React.FC<Props> = ({
  defaultObject,
  onSubmit,
  loading,
}) => {
  const { LL } = useLabel();

  const [obj, disabled, setObjField, , , , vResults] = useForm<PaypalWalletDto>(
    defaultObject,
    RequiredValidator.getValidators([
      "client_id",
      "client_secret",
      "app_id",
      "idcurrency",
      "mode"
    ])
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onSubmit(obj);
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box>
          <MyTextField
            field="priority"
            label={LL("field_priority")}
            setObjectField={setObjField}
            value={obj.priority}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <StatusSelect setObjField={setObjField} value={obj.status} />
        </Box>

        <Box mt={3}>
          <MyTextField
            field="mode"
            label={LL("mode")}
            setObjectField={setObjField}
            value={obj.mode}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <MyTextField
            field="client_id"
            label={LL("client_id")}
            setObjectField={setObjField}
            value={obj.client_id}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <MyTextField
            field="client_secret"
            label={LL("client_secret")}
            setObjectField={setObjField}
            value={obj.client_secret}
            vResults={vResults}
          />
        </Box>
        <Box mt={3}>
          <MyTextField
            field="payment_action"
            label={LL("payment_action")}
            setObjectField={setObjField}
            value={obj.payment_action}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <MyTextField
            field="app_id"
            label={LL("app_id")}
            setObjectField={setObjField}
            value={obj.app_id}
            vResults={vResults}
          />
        </Box>

        <Box mt={3}>
          <SelectCurrency
            setObjectField={setObjField}
            value={obj.idcurrency}
            field="idcurrency"
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { FormPaypalWallet };

import { Box } from "@mui/material";
import { ButtonWithLoading } from "components/elements/button/ButtonWithLoading";
import { PasswordField } from "components/elements/form/PasswordField";
import { ChangePasswordDto } from "dto/user/user.dto";
import { useForm } from "hooks/useForm";
import { useLabel } from "hooks/useLabel";
import { CallbackType } from "interfaces/commontypes.interface";

import React, { useState } from "react";
import RequiredValidator from "validators/required.validator";

type Props = {
  onSubmit: (obj: ChangePasswordDto, cb: CallbackType) => void;
};
const ChangePasswordForm: React.FC<Props> = ({ onSubmit }) => {
  const { LL } = useLabel();
  const [loading, setLoading] = useState(false);

  const customValidation = () => {
    if (!obj.password || !obj.confirmpassword) return true;
    if (obj.password === obj.confirmpassword) {
      return false;
    }
    return true;
  };

  const [obj, disabled, setObjField, , , setObject,vResult] =
    useForm<ChangePasswordDto>(
      new ChangePasswordDto(),
      RequiredValidator.getValidators(["confirmpassword", "password"]),
      customValidation
    );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    onSubmit(obj, cb);
  };

  const cb = () => {
    setLoading(false);
    setObject(new ChangePasswordDto());
  };

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box mt={3}>
          <PasswordField
            field="password"
            label={LL("field_password")}
            setObjectField={setObjField}
            value={obj.password}
            vResults={vResult}
          />
        </Box>
        <Box mt={3}>
          <PasswordField
            field="confirmpassword"
            label={LL("field_confirmpassword")}
            setObjectField={setObjField}
            value={obj.confirmpassword}
            vResults={vResult}
          />
        </Box>
        <Box mt={3}>
          <ButtonWithLoading
            type="submit"
            disabled={loading || disabled}
            loading={loading}
          >
            {LL("btn_submit")}
          </ButtonWithLoading>
        </Box>
      </form>
    </Box>
  );
};

export { ChangePasswordForm };

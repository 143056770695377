import React from "react";

import { IPage } from "interfaces/page.interface";
import { UserService } from "services/user/user.service";
import { UserDto } from "dto/user/user.dto";
import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";
import { FormUser } from "components/user/user/FormUser";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box, Tab, Tabs } from "@mui/material";
import { useLabel } from "hooks/useLabel";
import { TabPanel } from "components/elements/display/TabPanel";
import { AddUserRole } from "components/user/userrole/AddUserRole";


const service = new UserService();
const EditUser: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    handleChangeTab,
    tabValue,
    listUrl
  } = useEditObject<UserDto>(mainObject, currentRoute, get);
  const { LL } = useLabel();

  const onSubmit = (obj: UserDto) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        needUpdate={true}
      listUrl={listUrl}
      />
      <Box maxWidth="md" className="tabs">
        <Tabs variant="scrollable" value={tabValue} onChange={handleChangeTab}>
          <Tab label={LL("Edit_User_main_Info")} value={"generalinfo"} />
          <Tab label={LL("Edit_role")} value={"role"} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={"generalinfo"}>
        <DataDisplayBox>
          <FormUser
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </DataDisplayBox>
      </TabPanel>
      <TabPanel value={tabValue} index={"role"}>
        <DataDisplayBox>
          <AddUserRole
            idUser={id}
            currentRoute={currentRoute}
            mainObject={mainObject}
          />
        </DataDisplayBox>
      </TabPanel>
    </React.Fragment>
  );
};

export { EditUser };

import React from "react";

import { IPage } from "interfaces/page.interface";
import { RoleService } from "services/user/role.service";
import { RoleDto } from "dto/user/role.dto";
import { Loading } from "components/elements/loading/Loading";
import { useEditObject } from "hooks/useEditObject";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { HeaderEdit } from "components/elements/edit/HeaderEdit";
import { FormRole } from "components/user/role/FormRole";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
const service = new RoleService();
const EditRole: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const { mainUrl, detailUrl, object, loading, setLoading, id, listUrl } =
    useEditObject<RoleDto>(mainObject, currentRoute, get);

  const onSubmit = (obj: RoleDto) => {
    if (!obj) return;
    setLoading(true);
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEdit
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        listUrl={listUrl}
      />
      <DataDisplayBox>
        <FormRole
          defaultObject={object}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { EditRole };



import { PaypalWalletRepository } from "repositories/sale/paypalwallet.repository";
import { GeneralService } from "services/general.service";

export class PaypalWalletService extends GeneralService<PaypalWalletRepository> {
  constructor() {
    super(new PaypalWalletRepository());
  }
}

import { GridRenderCellParams } from "@mui/x-data-grid";
import { ColumnStatus } from "components/sale/order/ColumnStatus";
import { ColumnPayment } from "components/sale/order/ColumnPayment";
import { ColumnInvoice } from "components/sale/order/ColumnInvoice";
import { ColumnDelivery } from "components/sale/order/ColumnDelivery";
import { ColumnClient } from "components/sale/order/ColumnClient";
import { ColumnDate } from "components/sale/order/ColumnDate";
import { ColumnComments } from "components/sale/order/ColumnComments";
import { ColumnAction } from "./ColumnAction";

export class OrderListColumns {
  static columnStatus = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnStatus object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "status";
    obj["headerName"] = LL("headerName_statuses");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 300;

    return obj;
  };

  static columnPayment = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnPayment object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "payment";
    obj["headerName"] = LL("headerName_payment");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 300;

    return obj;
  };

  static columnInvoice = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnInvoice object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "invoice";
    obj["headerName"] = LL("headerName_invoice");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 300;

    return obj;
  };

  static columnDelivery = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnDelivery object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "delivery";
    obj["headerName"] = LL("headerName_delivery");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 300;

    return obj;
  };

  static columnClient = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnClient object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "client";
    obj["headerName"] = LL("headerName_client");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 300;

    return obj;
  };

  static columnDate = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnDate object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "date";
    obj["headerName"] = LL("headerName_date");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 180;

    return obj;
  };

  static columnComments = (LL: any) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return <ColumnComments object={params.row} />;
        } else {
          return "";
        }
      },
    };

    obj["field"] = "comments";
    obj["headerName"] = LL("headerName_comments");
    obj["hideable"] = true;
    obj["sortable"] = false;

    obj["flex"] = 1;
    obj["minWidth"] = 250;

    return obj;
  };

  static columnAction = (LL: any, handleCallback?: () => void) => {
    const obj: any = {
      renderCell: (params: GridRenderCellParams<any>) => {
        if (params.row !== undefined) {
          return (
            <ColumnAction
              object={params.row}
              callbackFunction={handleCallback ?? (() => {})}
            />
          );
        } else {
          return "";
        }
      },
    };

    obj["field"] = "action_order";
    obj["headerName"] = LL("headerName_action_order");
    obj["hideable"] = true;
    obj["sortable"] = false;

    // obj["flex"] = 1;
    obj["width"] = 200;
    obj["align"] = "center";
    obj["type"] = "action";

    return obj;
  };
}

import React, { useState } from "react";
import { FilterDictionarService } from "services/product/filterdictionar.service";

import { IPage } from "interfaces/page.interface";
import { useGetUrls } from "hooks/useGetUrls";
import { FilterDictionarDto } from "dto/product/filterdictionar.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";
import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { FormFilterDictionar } from "components/product/filterdictionar/FormFilterDictionar";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { useLanguage } from "providers/LanguageProvider";

const service = new FilterDictionarService();
const AddFilterDictionar: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject, currentRoute);
  const [loading, setLoading] = useState<boolean>(false);
  const { idSelectedLanguage } = useLanguage();
  const onSubmit = (obj: FilterDictionarDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      {
        mainObject,
        cb: CommonTools.processLoadingCallback(setLoading),
        idLanguage: CommonTools.processObjectField(obj, ["_idlang"]),
      },
      obj
    );
  };

  if (!currentRoute) return null;
  if (!mainObject) return null;
  const idFilter = CommonTools.processObjectField(currentRoute, [
    "historystate",
    "idFilter",
  ]);
  const selectedFilter = idFilter ? parseInt(idFilter) : undefined;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormFilterDictionar
          defaultObject={
            new FilterDictionarDto(
              idSelectedLanguage,
              undefined,
              selectedFilter
            )
          }
          onSubmit={onSubmit}
          loading={loading}
          forAdd
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { AddFilterDictionar };

import React from "react";

import { IPage } from "interfaces/page.interface";
import { OfferFilterService } from "services/product/offerfilter.service";
import { OfferFilterDto } from "dto/product/offerfilter.dto";
import { Loading } from "components/elements/loading/Loading";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FormOfferFilter } from "components/special/offerfilter/FormOfferFilter";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";
import { useEditSpecialThirdLevel } from "hooks/useEditSpecialThirdLevel";
import { HeaderEditSpecialThirdLevel } from "components/elements/edit/HeaderEditSpecialThirdLevel";

const service = new OfferFilterService();
const SpecialOfferFilterEdit: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any) => {
    service.get(id, cb, cbParams);
  };

  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    parentId,
    parentType,
    firstLevelParentId,
    firstLevelParentType,
    listUrl,
    listUrlSecond,
    listUrlThird
  } = useEditSpecialThirdLevel<OfferFilterDto>(
    "offerfilter",
    mainObject,
    currentRoute,
    get,
    false
  );

  const onSubmit = (obj: OfferFilterDto) => {
    if (!obj) return;
    setLoading(true);

    obj.idoffer = parseInt(parentId.toString());
    service.update(
      id,
      ComponentCommonTools.handleSuccessAddAndEditSpecialThirdLevel,
      {
        mainObject,
        parentType,
        parentId,
        specialPart: "offerfilter",
        firstLevelParentId,
        firstLevelParentType,
        cb: CommonTools.processLoadingCallback(setLoading),
      },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecialThirdLevel
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        parentId={parentId}
        parentType={parentType}
        specialType="offerfilter"
        firstLevelParentId={firstLevelParentId}
        firstLevelParentType={firstLevelParentType}
        listSecondUrl={listUrlSecond}
        listThirdUrl={listUrlThird}
        listUrl={listUrl}
      />
      <DataDisplayBox>
        <Box mt={3}>
          <FormOfferFilter
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialOfferFilterEdit };

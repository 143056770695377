import { OrderListColumns } from "components/sale/order/OrderListColumns";
import { DeliveryMethodDto } from "dto/delivery/deliverymethod.dto";
import { CountryDto } from "dto/static/country.dto";
import { LocationDto } from "dto/static/location.dto";
import { DetailObject } from "interfaces/detailobject.interface";
import Idto from "interfaces/idto.interface";
import { Types } from "tools/types/types";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { ListColumns } from "tools/utils/listcolumns.tool";

export class InvoiceDto implements Idto {
  id?: number;

  uuid?: string;

  idorder?: number;
  inovicetype?: number;

  status?: number;
  paystatus?: number;
  paymethod?: number;

  date?: number;
  datedelivery?: number;
  datepayment?: number;

  pricewovat?: number;
  vattotal?: number;
  pricetotal?: number;

  discount?: number;
  realprice?: number;

  priceadvance?: number;
  pricedelivery?: number;

  pricepaid?: number;
  pricedue?: number;

  coments?: string;

  destinatar_idcountry?: number;
  destinatar_idlocation?: number;
  destinatar_locationcode?: string;
  destinatar_address?: string;

  client_id?: number;
  client_name?: string;
  client_mobil?: string;
  client_email?: string;
  client_othercontacts?: string;

  client_type?: number;
  client_fct_fiz_name?: string;
  client_fct_fiz_idno?: string;
  client_fct_fiz_docnumber?: string;
  client_fct_fiz_address?: string;
  client_fct_fiz_phone?: string;

  client_fct_jur_company?: string;
  client_fct_jur_idno?: string;
  client_fct_jur_othercode?: string;
  client_fct_jur_address?: string;
  client_fct_jur_iban?: string;
  client_fct_jur_bank?: string;
  client_fct_jur_phone?: string;

  iddeliverymethod?: number;
  delivery_tracking?: string;
  delivery_tracking_url?: string;
  delivery_comments?: string;

  countryObj?: CountryDto;
  locationObj?: LocationDto;
  deliveryMethodObj?: DeliveryMethodDto;

  constructor(
    id?: number,
    uuid?: string,
    idorder?: number,
    inovicetype?: number,
    status?: number,
    paystatus?: number,
    paymethod?: number,
    date?: number,
    datedelivery?: number,
    datepayment?: number,
    pricewovat?: number,
    vattotal?: number,
    pricetotal?: number,
    discount?: number,
    realprice?: number,
    priceadvance?: number,
    pricedelivery?: number,
    pricepaid?: number,
    pricedue?: number,
    coments?: string,
    destinatar_idcountry?: number,
    destinatar_idlocation?: number,
    destinatar_locationcode?: string,
    destinatar_address?: string,
    client_id?: number,
    client_name?: string,
    client_mobil?: string,
    client_email?: string,
    client_othercontacts?: string,
    client_type?: number,
    client_fct_fiz_name?: string,
    client_fct_fiz_idno?: string,
    client_fct_fiz_docnumber?: string,
    client_fct_fiz_address?: string,
    client_fct_fiz_phone?: string,
    client_fct_jur_company?: string,
    client_fct_jur_idno?: string,
    client_fct_jur_othercode?: string,
    client_fct_jur_address?: string,
    client_fct_jur_iban?: string,
    client_fct_jur_bank?: string,
    client_fct_jur_phone?: string,
    iddeliverymethod?: number,
    delivery_tracking?: string,
    delivery_tracking_url?: string,
    delivery_comments?: string,
    countryObj?: CountryDto,
    locationObj?: LocationDto,
    deliveryMethodObj?: DeliveryMethodDto
  ) {
    this.id = id || 0;
    this.uuid = uuid || "";
    this.idorder = idorder || 0;
    this.inovicetype = inovicetype || 0;
    this.status = status || 0;
    this.paystatus = paystatus || 0;
    this.paymethod = paymethod || 0;
    this.date = date || 0;
    this.datedelivery = datedelivery || 0;
    this.datepayment = datepayment || 0;
    this.pricewovat = pricewovat || 0;
    this.vattotal = vattotal || 0;
    this.pricetotal = pricetotal || 0;
    this.discount = discount || 0;
    this.realprice = realprice || 0;
    this.priceadvance = priceadvance;
    this.pricedelivery = pricedelivery || 0;
    this.pricepaid = pricepaid || 0;
    this.pricedue = pricedue || 0;
    this.coments = coments || "";
    this.destinatar_idcountry = destinatar_idcountry || 0;
    this.destinatar_idlocation = destinatar_idlocation || 0;
    this.destinatar_locationcode = destinatar_locationcode || "";
    this.destinatar_address = destinatar_address || "";
    this.client_id = client_id || 0;
    this.client_name = client_name || "";
    this.client_mobil = client_mobil || "";
    this.client_email = client_email || "";
    this.client_othercontacts = client_othercontacts || "";
    this.client_type = client_type || 0;
    this.client_fct_fiz_name = client_fct_fiz_name || "";
    this.client_fct_fiz_idno = client_fct_fiz_idno || "";
    this.client_fct_fiz_docnumber = client_fct_fiz_docnumber || "";
    this.client_fct_fiz_address = client_fct_fiz_address || "";
    this.client_fct_fiz_phone = client_fct_fiz_phone || "";
    this.client_fct_jur_company = client_fct_jur_company || "";
    this.client_fct_jur_idno = client_fct_jur_idno || "";
    this.client_fct_jur_othercode = client_fct_jur_othercode || "";
    this.client_fct_jur_address = client_fct_jur_address || "";
    this.client_fct_jur_iban = client_fct_jur_iban || "";
    this.client_fct_jur_bank = client_fct_jur_bank || "";
    this.client_fct_jur_phone = client_fct_jur_phone || "";
    this.iddeliverymethod = iddeliverymethod || 0;
    this.delivery_tracking = delivery_tracking || "";
    this.delivery_tracking_url = delivery_tracking_url || "";
    this.delivery_comments = delivery_comments || "";
    this.countryObj = countryObj || new CountryDto();
    this.locationObj = locationObj || new LocationDto();
    this.deliveryMethodObj = deliveryMethodObj || new DeliveryMethodDto();
  }

  static getDetailFields(): Array<DetailObject> {
    return [
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Status",
        value: ["status_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Type",
        value: ["inovicetype_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "StatusPayment",
        value: ["paystatus_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PaymentMethod",
        value: ["paymethod_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Date",
        value: ["date_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "DateDelivery",
        value: ["datedelivery_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "DatePayment",
        value: ["datepayment_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceWovat",
        value: ["pricewovat"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "VatTotal",
        value: ["vattotal"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceTotal",
        value: ["pricetotal"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Discount",
        value: ["discount"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "RealPrice",
        value: ["realprice"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceAdvance",
        value: ["priceadvance"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceDelivery",
        value: ["pricedelivery"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PricePaid",
        value: ["pricepaid"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "PriceDue",
        value: ["pricedue"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Coments",
        value: ["coments"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "destinatar_address",
        value: ["destinatar_address"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_type",
        value: ["client_type_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_email",
        value: ["client_email"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_mobil",
        value: ["client_mobil"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_othercontacts",
        value: ["client_othercontacts"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_fct_fiz_name",
        value: ["client_fct_fiz_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_fct_fiz_idno",
        value: ["client_fct_fiz_idno"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_fct_fiz_docnumber",
        value: ["client_fct_fiz_docnumber"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_fct_fiz_address",
        value: ["client_fct_fiz_address"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_fct_fiz_phone",
        value: ["client_fct_fiz_phone"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_fct_jur_company",
        value: ["client_fct_jur_company"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "client_fct_jur_idno",
        value: ["client_fct_jur_idno"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "deliveryMethodObj",
        value: ["deliveryMethodObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "DeliveryMethod",
        value: ["deliveryMethodObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Country",
        value: ["countryObj", "name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "Location",
        value: ["locationObj", "_name"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "delivery_tracking",
        value: ["delivery_tracking"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "delivery_tracking_url",
        value: ["delivery_tracking_url"],
      },
      {
        typeField: Types.FIELD_TYPE_TEXT,
        label: "delivery_comments",
        value: ["delivery_comments"],
      },
    ];
  }

  static getColumns(
    LL: (str: string) => void,
    mainObj: string,
    deleteFun?: (obj: any) => void,
    cbParent?: any,
    specialType?: string
  ): Array<any> {
    return [
      ComponentCommonTools.columns_GetCheck(),
      ComponentCommonTools.columns_StandardColumn(LL, "id", "#"),
      ComponentCommonTools.columns_ConstantColumn(LL, "inovicetype"),
      OrderListColumns.columnClient(LL),
      OrderListColumns.columnStatus(LL),
      OrderListColumns.columnPayment(LL),
      OrderListColumns.columnInvoice(LL),
      OrderListColumns.columnDelivery(LL),
      OrderListColumns.columnDate(LL),
      OrderListColumns.columnComments(LL),
      ListColumns.columns_Print(LL, "printurl"),
      ComponentCommonTools.columns_ActionsColumn(
        LL,
        mainObj,
        deleteFun,
        ["detail"],
        [],
        cbParent,
        specialType
      ),
    ];
  }
}

import React, { useState } from "react";
import {PaypalWalletService} from "services/sale/paypalwallet.service";
import { IPage } from "interfaces/page.interface";
import { useGetUrls } from "hooks/useGetUrls";
import { PaypalWalletDto } from "dto/sale/paypalwallet.dto";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { CommonTools } from "tools/utils/common.tool";
import { HeaderAdd } from "components/elements/add/HeaderAdd";
import { FormPaypalWallet } from "components/sale/paypalwallet/FormPaypalWallet";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";

const service = new PaypalWalletService();
const AddPaypalWallet: React.FC<IPage> = ({ currentRoute, mainObject }) => {
  const { mainUrl, listUrl } = useGetUrls(mainObject, currentRoute);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = (obj: PaypalWalletDto) => {
    if (!obj) return;
    setLoading(true);
    service.add(
      ComponentCommonTools.handleSuccessAddAndEdit,
      { mainObject, cb: CommonTools.processLoadingCallback(setLoading) },
      obj
    );
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  return (
    <React.Fragment>
      <HeaderAdd mainObject={mainObject} mainUrl={mainUrl} listUrl={listUrl} />
      <DataDisplayBox>
        <FormPaypalWallet
          defaultObject={new PaypalWalletDto()}
          onSubmit={onSubmit}
          loading={loading}
        />
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { AddPaypalWallet };

import React from "react";

import { IPage } from "interfaces/page.interface";
import { FAQAnswerService } from "services/static/faqanswer.service";
import { FAQAnswerDto } from "dto/static/faqanswer.dto";
import { Loading } from "components/elements/loading/Loading";
import { CommonTools } from "tools/utils/common.tool";
import { ComponentCommonTools } from "tools/utils/componentcommon.tool";
import { FormFAQAnswer } from "components/special/faqanswer/FormFAQAnswer";
import { DataDisplayBox } from "components/elements/display/DataDisplayBox";
import { Box } from "@mui/material";
import { LanguageSelect } from "components/static/language/LanguageSelect";
import { useEditSpecial } from "hooks/useEditSpecial";
import { HeaderEditSpecial } from "components/elements/edit/HeaderEditSpecial";
import { useResource } from "hooks/useResource";

const service = new FAQAnswerService();
const SpecialFAQAnswerEdit: React.FC<IPage> = ({
  currentRoute,
  mainObject,
}) => {
  const get = (id: string, cb: any, cbParams: any, idLanguage?: string) => {
    service.get(id, cb, cbParams, idLanguage);
  };
  const { getCookieStayOnPage } = useResource();
  const typeCallback = getCookieStayOnPage(mainObject || "");
  const {
    mainUrl,
    detailUrl,
    object,
    loading,
    setLoading,
    id,
    idLanguage,
    setIdLanguage,
    parentId,
    parentType,
    getByLanguage,
    listUrl,
    listUrlSecond,
  } = useEditSpecial<FAQAnswerDto>(
    "faqanswer",
    mainObject,
    currentRoute,
    get,
    true
  );

  const onSubmit = (obj: FAQAnswerDto) => {
    if (!obj) return;
    setLoading(true);
    obj._idlang = parseInt(idLanguage.toString());
    obj.idfaq = parseInt(parentId.toString());

    const callBackFunction = typeCallback
      ? getByLanguage
      : ComponentCommonTools.handleSuccessAddAndEditSpecial;
    const callBackParams = typeCallback
      ? {}
      : {
          mainObject,
          parentType,
          parentId,
          specialPart: "faqanswer",
          cb: CommonTools.processLoadingCallback(setLoading),
          idLanguage,
        };
    service.update(id, callBackFunction, callBackParams, obj);
  };
  if (!currentRoute) return null;
  if (!mainObject) return null;
  if (loading) return <Loading />;
  if (!object) return null;

  return (
    <React.Fragment>
      <HeaderEditSpecial
        mainObject={mainObject}
        mainUrl={mainUrl}
        detailUrl={detailUrl}
        object={object}
        idLanguage={idLanguage}
        parentId={parentId}
        parentType={parentType}
        specialType="faqanswer"
        listUrl={listUrl}
        listUrlSecond={listUrlSecond}
      />
      <DataDisplayBox>
        <Box>
          <LanguageSelect value={idLanguage} setValue={setIdLanguage} />
        </Box>

        <Box mt={3}>
          <FormFAQAnswer
            defaultObject={object}
            onSubmit={onSubmit}
            loading={loading}
          />
        </Box>
      </DataDisplayBox>
    </React.Fragment>
  );
};

export { SpecialFAQAnswerEdit };

import { useEffect, useMemo, useState } from "react";
import { useResource } from "./useResource";

type Props = (useDefaultApproach?: boolean) => {
  listUrlSecond: string;
  setListUrlSecond: (listUrlSecond: string) => void;
};

const useGetSecondUrlsList: Props = (useDefaultApproach) => {
  if (!useDefaultApproach) useDefaultApproach = true;

  const [listUrlSecond, setListUrlSecond] = useState("");

  const { _setListSecondUrl } = useResource();

  useEffect(() => {
    if (useDefaultApproach) {
      _setListSecondUrl(listUrlSecond);
    }
  }, [listUrlSecond]);

  return useMemo(
    () => ({
      listUrlSecond,
      setListUrlSecond,
    }),
    [listUrlSecond]
  );
};

export { useGetSecondUrlsList };

import SelectOptions from "dto/app/selectoptions.dto";

export class UserConfigType {
  public static _LL: any = false;
  public static setLL(_l: any): any {
    this._LL = _l;
  }

  public static FIRST_NAME = 1;
  public static SECOND_NAME = 2;

  public static BIRTHDATE = 10;
  public static DAY_B = 11;
  public static MONTH_B = 12;
  public static YEAR_B = 13;

  public static _constants: { [key: number]: string } = {
    1: "FIRST_NAME",
    2: "SECOND_NAME",
    11: "DAY_B",
    12: "MONTH_B",
    13: "YEAR_B",
  };

  // TODO: Implement this
  public static LL = (key: any) => {
    const k: number = parseInt(key);

    if (!this._constants.hasOwnProperty(k)) return "";
    if (!UserConfigType._LL) return "";
    return this._LL("UserConfigType_" + this._constants[k].toString());
  };

  public static GA_KEYS = (key: string): Array<number> => {
    let rez: Array<number> = [];

    if (key === "clasificator") {
      rez = [
        this.FIRST_NAME,
        this.SECOND_NAME,
        this.DAY_B,
        this.MONTH_B,
        this.YEAR_B,
      ];
    }

    return rez;
  };

  public static GA = (
    key: string,
    emptyValue?: boolean,
    emptyLabel?: string
  ) => {
    const keys = this.GA_KEYS(key);

    const rez: Array<SelectOptions> = [];
    if (emptyValue) {
      const empty = new SelectOptions();
      empty.value = "";
      empty.label = emptyLabel ?? UserConfigType._LL("all");
      rez.push(empty);
    }
    keys.forEach((k) => {
      const so = new SelectOptions();
      so.value = k;
      so.label = UserConfigType.LL(k);
      rez.push(so);
    });

    return rez;
  };
}

import GeneralRepository from "../general.repository";
import IGeneralRepository from "interfaces/irepository.interface";

export class PaypalTransactionRepository
  extends GeneralRepository
  implements IGeneralRepository
{
  constructor() {
    super("paypaltransaction");
  }
}

import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTools, Time } from "tools/utils/date.tool";
import { useLabel } from "hooks/useLabel";


type Props = {
  setObjField: (field: any, value: any) => void;
  field: string;
  _defaultValue?: number;
  _label?: string;
  value?: number | null;
  time?: Time;
};

const MyDatePickerTimestamp: React.FC<Props> = ({
  setObjField,
  value,
  field,
  _defaultValue,
  _label,
  time,
  ...props
}) => {
  const { LL } = useLabel();
  const label = _label !== undefined ? _label : LL("FN_" + field);
  const defaultValue =
    _defaultValue !== undefined
      ? DateTools.timestampToDayJs(_defaultValue)
      : DateTools.timestampToDayJs(DateTools.getTimeStamp(undefined, time));

  const handleOnChange = (e: any) => {
    if (!e) return;
    setObjField(field, DateTools.dayJsToTimestamp(e, time));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={label}
        onChange={handleOnChange}
        defaultValue={defaultValue}
        value={DateTools.timestampToDayJs(value)}
        sx={{ width: "100%", maxWidth: "400px" }}
        format={DateTools.getDateFormatDayJs()}
      />
    </LocalizationProvider>
  );
};

export { MyDatePickerTimestamp };
